<template>
  <div class="wrapper-default-page reports-page" :class="[isThemeHeader === 'true' ? 'white-color' : 'dark-color', showFilters ? '' : 'hiddenFilters']">
    <div class="d-flex flex-wrap gap-2 mb-2">
      <h5 class="mb-0 d-inline-block align-self-center">{{ $t('broadcastSideBar.reports') }}</h5>
      <MultiSelect
        v-model="selectedReport"
        class="multiselect-sm w-270px reports-select"
        :options="reportsList"
        :placeholder="$t('table.selectReportType')"
        label="name"
        track-by="id"
        :allow-empty="false"
        @input="resetTables()"
      ></MultiSelect>
      <template v-if="selectedReport">
        <b-button size="sm" @click="showFilters = !showFilters">
          {{ showFilters ? $t('reports.hideFilters') : $t('reports.showFilters') }}
        </b-button>
        <b-button size="sm" @click="resetFilters">
          {{ $t('reports.clearFilters') }}
        </b-button>
        <b-button size="sm" class="ml-3" :disabled="checkNotValid" :title="showDisabledExplanation" @click="loadReport">
          {{ $t('reports.loadReport') }}
        </b-button>
        <b-button
          v-if="selectedReport && selectedReport.id === 4 && $checkPermissions('calendar_plan.update_or_create')"
          size="sm"
          class="mx-3"
          :disabled="checkNotValid || !(selects.commercial && selects.commercial.id) || selects.channels.length !== 1 || !selects.target"
          :title="$t('reports.selectYearCommercialAndChannelFirst')"
          @click="loadDataAndOpenCPModal"
        >
          {{ $t('reports.editData') }}
        </b-button>
        <b-button size="sm" :disabled="checkNotValid || processing" :title="showDisabledExplanation" @click="exportReport">
          {{ processing ? $t('table.exporting') + '...' : $t('table.export') }}
        </b-button>
        <b-button
          v-if="selectedReport && selectedReport.id === 0"
          size="sm"
          :disabled="checkNotValid || processingCsv"
          :title="showDisabledExplanation"
          @click="exportReportCsv"
        >
          {{ processingCsv ? $t('table.exporting') + '...' : $t('table.export') + ' (csv)' }}
        </b-button>
        <router-link :to="{ name: 'profile' }" class="btn btn-outline-danger btn-sm ml-3">
          {{ $t('broadcastSideBar.exit') }}
        </router-link>
      </template>
    </div>
    <div v-if="selectedReport" :class="showFilters ? 'd-flex' : 'd-none'" class="flex-wrap gap-2 mb-2">
      <b-form-group
        v-show="isFilterIncluded.includes('date_from')"
        class="mb-0"
        :label="$t('channelModal.from')"
        label-for="datepicker-buttons-from"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <datepicker-wrapper
          id="datepicker-buttons-from"
          v-model="selects.date_from"
          class="w-250px"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          size="sm"
          @input="selectedDateFrom"
        />
      </b-form-group>
      <b-form-group
        v-show="isFilterIncluded.includes('date_to')"
        class="mb-0"
        :label="$t('channelModal.to')"
        label-for="datepicker-buttons-to"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <datepicker-wrapper
          id="datepicker-buttons-to"
          v-model="selects.date_to"
          class="w-250px"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          size="sm"
          :min="selects.date_from"
          @input="selectedFilter"
        />
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('channel_id')" class="mb-0" :label="$t('channelModal.channel')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.channel"
          class="multiselect-sm w-250px"
          :options="filterChannelsList"
          :placeholder="$t('channelModal.channel')"
          label="name"
          track-by="id"
          @input="selectedFilter"
        ></MultiSelect>
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('channel_ids')" class="mb-0" :label="$t('sideBar.channels')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.channels"
          class="multiselect-sm w-250px"
          :options="filterChannelsList"
          :placeholder="$t('sideBar.channels')"
          label="name"
          track-by="id"
          :multiple="true"
          :close-on-select="false"
          @input="selectChannels"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single text-dark">{{ $t('multiselect.selected') }} {{ values.length }}</span>
          </template>
        </MultiSelect>
      </b-form-group>
      <b-form-group
        v-show="isFilterIncluded.includes('subchannel_ids')"
        class="mb-0"
        :label="$t('subChannels.subchannels')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <MultiSelect
          v-model="selects.subchannels"
          class="multiselect-sm w-250px"
          :options="filterSubchannelsList"
          :placeholder="$t('subChannels.subchannels')"
          label="name"
          track-by="id"
          :multiple="true"
          :close-on-select="false"
          @input="selectSubchannels"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single text-dark">{{ $t('multiselect.selected') }} {{ values.length }}</span>
          </template>
        </MultiSelect>
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('agency_id')" class="mb-0" :label="$t('table.agency')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.agency"
          class="multiselect-sm w-250px"
          :options="filterAgenciesList"
          :placeholder="$t('table.selectAgency')"
          label="name"
          track-by="id"
          @input="selectAgency()"
        ></MultiSelect>
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('agency_ids')" class="mb-0" :label="$t('table.agency')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.agencies"
          class="multiselect-sm w-250px"
          :options="filterAgenciesList"
          :placeholder="$t('table.selectAgency')"
          label="name"
          track-by="id"
          :multiple="true"
          :close-on-select="false"
          @input="selectAgencies()"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single text-dark">{{ $t('multiselect.selected') }} {{ values.length }}</span>
          </template>
        </MultiSelect>
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('advertiser_id')" class="mb-0" :label="$t('table.advertiser')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.advertiser"
          class="multiselect-sm w-250px"
          :options="filterAdvertisersList"
          :placeholder="$t('table.selectAdvertiser')"
          label="name"
          track-by="id"
          :disabled="!(selects.agency && selects.agency.id)"
          @input="selectAdvertiser()"
        ></MultiSelect>
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('advertiser_ids')" class="mb-0" :label="$t('table.advertisers')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.advertisers"
          class="multiselect-sm w-250px"
          :options="filterAdvertisersList"
          :placeholder="$t('table.selectAdvertisers')"
          label="name"
          track-by="id"
          :multiple="true"
          :close-on-select="false"
          :loading="advertisersLoadingStatus"
          :internal-search="selects.agencies.length > 0 ? true : false"
          @search-change="asyncFindAdvertisers"
          @input="selectAdvertisers()"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single text-dark">{{ $t('multiselect.selected') }} {{ values.length }}</span>
          </template>
        </MultiSelect>
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('brand_id')" class="mb-0" :label="$t('table.brand')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.brand"
          class="multiselect-sm w-250px"
          :options="brandsList || []"
          :placeholder="$t('table.selectGroup')"
          label="name"
          track-by="id"
          :disabled="!(selects.advertiser && selects.advertiser.id)"
          @input="updateQuery()"
        ></MultiSelect>
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('brand_ids')" class="mb-0" :label="$t('table.brand')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.brands"
          class="multiselect-sm w-250px"
          :options="filterBrandsList"
          :placeholder="$t('table.selectGroup')"
          label="name"
          track-by="id"
          :multiple="true"
          :close-on-select="false"
          :loading="brandsLoadingStatus"
          :internal-search="selects.brands.length > 0 ? true : false"
          @search-change="asyncFindBrands"
          @input="selectBrands()"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single text-dark">{{ $t('multiselect.selected') }} {{ values.length }}</span>
          </template>
        </MultiSelect>
      </b-form-group>
      <b-form-group
        v-show="isFilterIncluded.includes('with_wgrp')"
        class="mb-0"
        :label="$t('reports.with') + (show_equals ? ' Equals' : ' wGRP')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <b-form-checkbox v-model="selects.with_wgrp" class="mt-1 ml-1" switch @input="updateQuery()">
          {{ $t('reports.with') }} {{ show_equals ? 'Equals' : 'wGRP' }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-show="isFilterIncluded.includes('target_audience_id')"
        class="mb-0"
        :label="$t('channelModal.measurements')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <MultiSelect
          v-model="selects.measurement"
          class="multiselect-sm w-250px"
          :options="measurementsList"
          :placeholder="$t('channelModal.measurements')"
          label="name"
          track-by="id"
          @input="selectedMeasurement()"
        ></MultiSelect>
      </b-form-group>
      <b-form-group
        v-show="isFilterIncluded.includes('target_audience_id')"
        class="mb-0"
        :label="$t('sideBar.targetaudience')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <MultiSelect
          v-model="selects.target"
          class="multiselect-sm w-250px"
          :options="targetList"
          :placeholder="$t('channelModal.target')"
          label="name"
          track-by="id"
          :disabled="!(selects.measurement && selects.measurement.id)"
          @input="updateQuery()"
        ></MultiSelect>
      </b-form-group>
      <b-form-group
        v-show="isFilterIncluded.includes('commercial_type_id')"
        class="mb-0"
        :label="$t('table.commercialType')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <MultiSelect
          v-model="selects.commercial"
          class="multiselect-sm w-250px"
          :options="commercialType"
          :placeholder="$t('table.selectCommercialType')"
          label="name"
          track-by="id"
          @input="updateQuery()"
        ></MultiSelect>
      </b-form-group>
      <b-form-group
        v-show="isFilterIncluded.includes('placement_type_id')"
        class="mb-0"
        :label="$t('table.placementType')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <MultiSelect
          v-model="selects.type"
          class="multiselect-sm w-250px"
          :options="placementType"
          :placeholder="$t('table.selectType')"
          label="name"
          track-by="id"
          @input="updateQuery()"
        ></MultiSelect>
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('year_id')" class="mb-0" :label="$t('channelModal.year')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.year"
          class="multiselect-sm w-250px"
          :options="yearsList"
          :placeholder="$t('channelModal.pickYear')"
          label="id"
          track-by="id"
          @input="selectedYear()"
        ></MultiSelect>
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('project_id')" class="mb-0" :label="$t('table.project')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.project"
          class="multiselect-sm w-250px"
          :options="filterProjectsList"
          :placeholder="$t('table.selectProject')"
          label="name"
          track-by="id"
          @input="updateQuery()"
        ></MultiSelect>
      </b-form-group>
      <b-form-group
        v-show="isFilterIncluded.includes('spot_fact_time')"
        class="mb-0"
        :label="$t('reports.filterSpotFact')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <select v-model="selects.spotFactTime" class="custom-select-sm custom-select w-250px" :disabled="checkNotValid" @input="updateQuery()">
          <option value="only">{{ $t('reports.onlySpotTime') }}</option>
          <option value="all">{{ $t('table.all') }}</option>
          <option value="without">{{ $t('reports.woSpotTime') }}</option>
        </select>
      </b-form-group>

      <b-form-group
        v-show="isFilterIncluded.includes('inventory_or_budget')"
        class="mb-0"
        :label="$t('reports.inventory') + '/' + $t('table.budget')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <select v-model="selects.inventory_or_budget" class="custom-select-sm custom-select w-250px" @input="updateQuery()">
          <option value="" disabled hidden></option>
          <option value="inventory">{{ $t('reports.inventory') }}</option>
          <option value="budget">{{ $t('table.budget') }}</option>
        </select>
      </b-form-group>

      <b-form-group
        v-show="isFilterIncluded.includes('time_interval')"
        class="mb-0"
        :label="$t('reports.timeInterval')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <select v-model="selects.time_interval" class="custom-select-sm custom-select w-250px" @input="updateQuery()">
          <option value="" disabled>{{ $t('reports.timeInterval') }}</option>
          <option value="5 minutes">5 {{ $t('reports.nMinutes') }}</option>
          <option value="10 minutes">10 {{ $t('reports.nMinutes') }}</option>
          <option value="15 minutes">15 {{ $t('reports.nMinutes') }}</option>
          <option value="half hour">{{ $t('reports.halfHour') }}</option>
          <option value="hour">{{ $t('reports.hour') }}</option>
          <!-- <option value="prime time">{{$t('channelModal.prime')}}</option>
        <option value="off-prime time">{{$t('channelModal.offPrime')}}</option> -->
        </select>
      </b-form-group>

      <b-form-group v-show="isFilterIncluded.includes('day_interval')" class="mb-0" :label="$t('reports.dayInterval')" label-size="sm" label-class="py-0 mb-0">
        <select v-model="selects.day_interval" class="custom-select-sm custom-select w-250px" @input="updateQuery()">
          <option value="" disabled>{{ $t('reports.dayInterval') }}</option>
          <option value="day">{{ $t('table.day') }}</option>
          <option value="week">{{ $t('table.week') }}</option>
          <option value="month">{{ $t('reports.month') }}</option>
        </select>
      </b-form-group>

      <b-form-group v-show="isFilterIncluded.includes('time_filter')" class="mb-0" :label="$t('reports.primeOffPrime')" label-size="sm" label-class="py-0 mb-0">
        <select v-model="selects.time_filter" class="custom-select-sm custom-select w-250px" @input="updateQuery()">
          <option value="" disabled>{{ $t('reports.primeOffPrime') }}</option>
          <option :value="null">{{ $t('table.all') }}</option>
          <option value="prime time">{{ $t('channelModal.prime') }}</option>
          <option value="off-prime time">{{ $t('channelModal.offPrime') }}</option>
        </select>
      </b-form-group>

      <b-form-group v-show="isFilterIncluded.includes('day_filter')" class="mb-0" :label="$t('reports.weekDaysType')" label-size="sm" label-class="py-0 mb-0">
        <select v-model="selects.day_filter" class="custom-select-sm custom-select w-250px" @input="updateQuery()">
          <option value="" disabled>{{ $t('reports.weekDaysType') }}</option>
          <option :value="null">{{ $t('table.all') }}</option>
          <option value="working days">{{ $t('reports.workDays') }}</option>
          <option value="weekends">{{ $t('reports.weekends') }}</option>
        </select>
      </b-form-group>

      <b-form-group v-show="isFilterIncluded.includes('rating_type')" class="mb-0" :label="$t('table.rating')" label-size="sm" label-class="py-0 mb-0">
        <select v-model="selects.rating_type" class="custom-select-sm custom-select w-250px" @input="updateQuery()">
          <option value="" disabled>{{ $t('table.rating') }}</option>
          <option value="plan_fact">{{ $t('table.plan') }}/{{ $t('table.fact') }}</option>
          <option value="plan">{{ $t('table.plan') }}</option>
          <option value="fact">{{ $t('table.fact') }}</option>
        </select>
      </b-form-group>

      <b-form-group v-show="isFilterIncluded.includes('grouping_type')" class="mb-0" :label="$t('table.grouping')" label-size="sm" label-class="py-0 mb-0">
        <select v-model="selects.grouping_type" class="custom-select-sm custom-select w-250px" @input="updateQuery()">
          <option value="" disabled>{{ $t('table.grouping') }}</option>
          <option :value="0">{{ $t('table.all') }}</option>
          <option :value="1">{{ $t('channelModal.channel') }}</option>
          <option v-if="selectedReport.id !== 3" :value="2">{{ $t('table.brand') }}</option>
          <option v-if="selectedReport.id !== 3" :value="3">{{ $t('channelModal.channel') }} & {{ $t('table.brand') }}</option>
        </select>
      </b-form-group>

      <b-form-group
        v-show="isFilterIncluded.includes('program_releases_names')"
        class="mb-0"
        :label="$t('table.programRelease')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <MultiSelect
          v-model="selects.program_releases_names"
          class="multiselect-sm"
          style="width: 508px !important"
          :options="programReleasesDistinctNames"
          :placeholder="$t('table.selectProgramRelease')"
          label="name"
          track-by="name"
          :multiple="true"
          :close-on-select="false"
          :disabled="!(selects.channel && selects.date_from && selects.date_to)"
          @input="updateQuery()"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single text-dark">{{ $t('multiselect.selected') }}: {{ values.length }} </span>
          </template>
        </MultiSelect>
      </b-form-group>

      <b-form-group v-show="isFilterIncluded.includes('time_start_at')" class="mb-0" :label="$t('table.start_at')" label-size="sm" label-class="py-0 mb-0">
        <BroadcastTimePicker
          v-model="selects.time_start_at"
          size="sm"
          style="width: 121px !important"
          :interval-mode="true"
          :min-time="0"
          :max-time="172798"
          @input="updateQuery()"
        />
      </b-form-group>
      <b-form-group v-show="isFilterIncluded.includes('time_end_at')" class="mb-0" :label="$t('table.end_at')" label-size="sm" label-class="py-0 mb-0">
        <BroadcastTimePicker
          v-model="selects.time_end_at"
          size="sm"
          style="width: 121px !important"
          :interval-mode="true"
          :min-time="0"
          :max-time="172798"
          @input="updateQuery()"
        />
      </b-form-group>

      <b-form-group
        v-if="$checkPermissions('special_discount.include', 'special_discount.exclude')"
        v-show="isFilterIncluded.includes('include_special_discounts')"
        class="mb-0"
        :label="$t('reports.withSpecialDiscounts')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <b-form-checkbox v-model="selects.include_special_discounts" class="mt-1 ml-1" switch> {{ $t('reports.withSpecialDiscounts') }}</b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="isAdmin || isSaleshouseManager || isChiefSaleshouse"
        v-show="isFilterIncluded.includes('with_discount_details')"
        class="mb-0"
        :label="$t('reports.discountDetails')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <b-form-checkbox v-model="selects.with_discount_details" class="mt-1 ml-1" switch @change="$store.commit('clearReportCosts')">
          {{ $t('reports.discountDetails') }}
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-if="$checkPermissions('report.mmr_kazakhstan')"
        v-show="isFilterIncluded.includes('mmr_2024_plus')"
        class="mb-0"
        :label="$t('reports.mmr2024Plus')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <b-form-checkbox v-model="selects.mmr_2024_plus" class="mt-1 ml-1" switch @change="$store.commit('clearReportMMRKazakhstan')">
          {{ $t('reports.mmr2024Plus') }}
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-show="isFilterIncluded.includes('show_only_monthly_totals')"
        class="mb-0"
        :label="$t('reports.showOnlyMonthlyTotals')"
        label-size="sm"
        label-class="py-0 mb-0"
      >
        <b-form-checkbox v-model="selects.show_only_monthly_totals" class="mt-1 ml-1" switch> {{ $t('reports.showOnlyMonthlyTotals') }}</b-form-checkbox>
      </b-form-group>
    </div>

    <Cost v-if="selectedReport && selectedReport.id === 0" :with_discount_details="selects.with_discount_details" />
    <BroadcastReference v-if="selectedReport && selectedReport.id === 1" :with_wgrp="selects.with_wgrp" />
    <ClientAdvertisers v-if="selectedReport && selectedReport.id === 2" />
    <ObligationExecutions v-if="selectedReport && selectedReport.id === 3" />
    <template v-if="selectedReport && selectedReport.id === 4">
      <SalesPlanExecutions :show_only_monthly_totals="selects.show_only_monthly_totals" />
      <ModalEditSalesPlanExecutions v-model="selects" @refreshReport="loadReport()" @updateQuery="updateQuery()" @selectedMeasurement="selectedMeasurement()" />
    </template>
    <template v-if="selectedReport && selectedReport.id === 12">
      <Subchannels v-model="selects" @refreshReport="loadReport()" @updateQuery="updateQuery()" @selectedMeasurement="selectedMeasurement()" />
    </template>
    <ByProjects v-if="selectedReport && selectedReport.id === 5" :selected_year="selects.year" />
    <ByProjectsShort v-if="selectedReport && selectedReport.id === 6" :selected_year="selects.year" />
    <Inventory v-if="selectedReport && selectedReport.id === 7" />
    <Programs v-if="selectedReport && selectedReport.id === 8" />
    <InventoryExtended v-if="selectedReport && selectedReport.id === 9" />
    <CategorySummary v-if="selectedReport && selectedReport.id === 10" />
    <MMRKazakhstan v-if="selectedReport && selectedReport.id === 11" :mmr_2024_plus="selects.mmr_2024_plus" />
  </div>
</template>
<script>
import MultiSelect from '@/components/MultiSelect';
import { mapGetters } from 'vuex';
import downloadFileHandler from '@/mixins/downloadFileHandler';
import reports from '@/store/reports/index';
import BroadcastTimePicker from '@/components/BroadcastTimePicker';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import debounce from '@/utils/debounce';
import { getAdvertisers, getBrands } from '@/api/apiServices';

export default {
  name: 'Reports',
  components: {
    MultiSelect,
    BroadcastTimePicker,
    DatepickerWrapper,
    Cost: () => import('@/components/Reports/Tables/Cost'),
    BroadcastReference: () => import('@/components/Reports/Tables/BroadcastReference'),
    ClientAdvertisers: () => import('@/components/Reports/Tables/ClientAdvertisers'),
    ObligationExecutions: () => import('@/components/Reports/Tables/ObligationExecutions'),
    SalesPlanExecutions: () => import('@/components/Reports/Tables/SalesPlanExecutions'),
    Subchannels: () => import('@/components/Reports/Tables/Subchannels'),
    ModalEditSalesPlanExecutions: () => import('@/components/Reports/ModalEditSalesPlanExecutions'),
    ByProjects: () => import('@/components/Reports/Tables/ByProjects'),
    ByProjectsShort: () => import('@/components/Reports/Tables/ByProjectsShort'),
    Inventory: () => import('@/components/Reports/Tables/Inventory'),
    Programs: () => import('@/components/Reports/Tables/Programs'),
    InventoryExtended: () => import('@/components/Reports/Tables/InventoryExtended'),
    CategorySummary: () => import('@/components/Reports/Tables/CategorySummary'),
    MMRKazakhstan: () => import('@/components/Reports/Tables/MMRKazakhstan'),
  },
  mixins: [downloadFileHandler],
  props: {
    report_type: { type: [String, Number], default: undefined },
    commercial_type_id: { type: [String, Number], default: undefined },
    agency_id: { type: [String, Number], default: undefined },
    agency_ids: { type: [String, Number], default: undefined },
    advertiser_id: { type: [String, Number], default: undefined },
    advertiser_ids: { type: String, default: undefined },
    brand_id: { type: [String, Number], default: undefined },
    brand_ids: { type: [String, Number], default: undefined },
    date_from: { type: String, default: undefined },
    date_to: { type: String, default: undefined },
    channel_id: { type: [String, Number], default: undefined },
    channel_ids: { type: String, default: undefined },
    subchannel_ids: { type: String, default: undefined },
    with_wgrp: { type: [String, Number], default: undefined },
    placement_type_id: { type: [String, Number], default: undefined },
    measurement_id: { type: [String, Number], default: undefined },
    target_id: { type: [String, Number], default: undefined },
    year: { type: [String, Number], default: undefined },
    time_interval: { type: String, default: undefined },
    inventory_or_budget: { type: String, default: undefined },
    project_id: { type: [String, Number], default: undefined },
    day_interval: { type: String, default: undefined },
    time_filter: { type: String, default: undefined },
    day_filter: { type: String, default: undefined },
    time_start_at: { type: String, default: undefined },
    time_end_at: { type: String, default: undefined },
    program_releases_names: { type: String, default: undefined },
    rating_type: { type: String, default: undefined },
    grouping_type: { type: [String, Number], default: undefined },
    spotFactTime: { type: [String], default: 'only' },
    with_discount_details: { type: [String, Number], default: undefined },
  },
  data() {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().slice(0, 10);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().slice(0, 10);
    //const envMinHourInSec = process.env.VUE_APP_BROADCAST_START_TIME ? +process.env.VUE_APP_BROADCAST_START_TIME : 21600; //default 06:00:00
    const envMinHourInSec = 0;
    return {
      selectedReport: '',
      selects: {
        date_from: firstDay,
        date_to: lastDay,
        agency: '',
        agencies: [],
        commercial: '',
        channel: '',
        channels: [],
        subchannels: [],
        brand: '',
        brands: [],
        advertiser: '',
        advertisers: [],
        type: '',
        measurement: '',
        target: '',
        with_wgrp: true,
        year: '',
        spotFactTime: 'only',
        time_interval: '', //5 minutes, 10 minutes, 15 minutes, half hour, hour, prime time off-prime time
        inventory_or_budget: 'inventory',
        project: '',
        day_interval: '', //day, week, month
        day_filter: null, //null (means all days), working days and weekends
        time_filter: null, //null (means all time), prime time and off-prime time
        time_start_at: envMinHourInSec,
        time_end_at: envMinHourInSec + 24 * 60 * 60 - 1,
        program_releases_names: [],
        include_special_discounts: true,
        rating_type: 'plan_fact', // 'plan_fact', 'plan, 'fact'
        grouping_type: 0,
        with_discount_details: false,
        mmr_2024_plus: true,
        show_only_monthly_totals: false,
      },
      filterAgenciesList: [],
      filterAdvertisersList: [],
      filterBrandsList: [],
      filterChannelsList: [],
      filterSubchannelsList: [],
      filterProjectsList: [],
      loading: true,
      showFilters: true,
      processing: false,
      processingCsv: false,
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      commercialType: 'getCommercialType',
      agenciesList: 'getAgenciesList',
      channels: 'getChannelsList',
      advertisersList: 'getAdvertisersList',
      advertisersStatus: 'getAdvertisersStatus',
      placementType: 'getPlacementType',
      brandsList: 'getBrandsList',
      yearsList: 'getYear',
      measurementsList: 'getMeasurementsList',
      targetList: 'getTargetList',
      projects: 'getProjectsList',
      subchannels: 'getSubchannelsList',
      programReleasesDistinctNames: 'getProgramReleasesDistinctNames',
      brandsStatus: 'getBrandsStatus',
      isChiefSaleshouse: 'getIsCurrentUserChiefSaleshouse',
      isSaleshouseManager: 'getIsCurrentUserSaleshouseManager',
      isAdmin: 'getIsCurrentUserAdmin',
      sh_settings: 'getSalehousesSettings',
      selectedSaleshouse: 'getSelectedSaleshouse',
    }),
    show_equals() {
      if (this.FEATURES.EQUALS) {
        return this.sh_settings[0]?.is_equals_enabled ?? false;
      } else {
        return false;
      }
    },
    advertisersLoadingStatus() {
      return this.advertisersStatus === 'loading' ? true : false;
    },
    brandsLoadingStatus() {
      return this.brandsStatus === 'loading' ? true : false;
    },
    reportsList() {
      const list = [];
      if (this.$checkPermissions('report.costs')) list.push({ id: 0, name: this.$i18n.t('reports.cost') });
      if (this.$checkPermissions('report.broadcast_reference')) list.push({ id: 1, name: this.$i18n.t('reports.etherReference') });
      if (this.$checkPermissions('report.clients_and_advertisers')) list.push({ id: 2, name: this.$i18n.t('reports.clientAdvertiser') });
      if (this.$checkPermissions('report.obligation_executions')) list.push({ id: 3, name: this.$i18n.t('reports.executionObligations') });
      if (this.$checkPermissions('report.sales_plan_executions')) list.push({ id: 4, name: this.$i18n.t('reports.salesPlanFulfillment') });
      if (this.$checkPermissions('report.projects')) list.push({ id: 5, name: this.$i18n.t('reports.byProjects') });
      if (this.$checkPermissions('report.projects_short')) list.push({ id: 6, name: this.$i18n.t('reports.byProjectsShort') });
      if (this.$checkPermissions('report.inventory')) list.push({ id: 7, name: this.$i18n.t('reports.inventory') });
      if (this.$checkPermissions('report.inventory_extended')) list.push({ id: 9, name: this.$i18n.t('reports.inventoryExtended') });
      if (this.$checkPermissions('report.programs')) list.push({ id: 8, name: this.$i18n.t('reports.programs') });
      if (this.$checkPermissions('report.category_summary')) list.push({ id: 10, name: this.$i18n.t('reports.categorySummary') });
      if (this.$checkPermissions('report.mmr_kazakhstan')) list.push({ id: 11, name: this.$i18n.t('reports.mmrKazakhstan') });
      if (this.FEATURES.SUBCHANNELS && this.$checkPermissions('report.sales_plan_executions_tv_network'))
        list.push({ id: 12, name: this.$i18n.t('reports.salesPlanFulfillmentTVNetwork') });
      return list;
    },
    showGroupingAllOption() {
      if (this.selectedReport.id === 0) {
        return true;
      } else if (this.selectedReport.id === 1) {
        return true;
      } else if (this.selectedReport.id === 7) {
        return false;
      }
    },
    isFilterIncluded() {
      if (this.selectedReport) {
        if (this.selectedReport.id === 0)
          return [
            'date_to',
            'date_from',
            'agency_ids',
            'advertiser_ids',
            'brand_ids',
            'channel_ids',
            'spot_fact_time',
            'include_special_discounts',
            'grouping_type',
            'with_discount_details',
          ];
        else if (this.selectedReport.id === 1)
          return ['date_to', 'date_from', 'agency_ids', 'advertiser_ids', 'brand_ids', 'channel_ids', 'with_wgrp', 'grouping_type'];
        else if (this.selectedReport.id === 2)
          return ['date_to', 'date_from', 'agency_id', 'advertiser_id', 'brand_id', 'channel_id', 'include_special_discounts'];
        else if (this.selectedReport.id === 3)
          return [
            'date_to',
            'date_from',
            'agency_id',
            'advertiser_id',
            'brand_id',
            'channel_ids',
            'commercial_type_id',
            'placement_type_id',
            'include_special_discounts',
            'grouping_type',
          ];
        else if (this.selectedReport.id === 4)
          return ['year_id', 'channel_ids', 'commercial_type_id', 'include_special_discounts', 'show_only_monthly_totals'];
        else if (this.selectedReport.id === 5)
          return [
            'year_id',
            'agency_ids',
            'advertiser_ids',
            'project_id',
            'channel_ids',
            'commercial_type_id',
            'placement_type_id',
            'inventory_or_budget',
            'include_special_discounts',
          ];
        else if (this.selectedReport.id === 6)
          return [
            'year_id',
            'agency_id',
            'project_id',
            'channel_ids',
            'commercial_type_id',
            'placement_type_id',
            'inventory_or_budget',
            'include_special_discounts',
          ];
        else if (this.selectedReport.id === 7)
          return [
            'channel_ids',
            'date_from',
            'date_to',
            'target_audience_id',
            'commercial_type_id',
            'time_interval',
            'day_interval',
            'time_filter',
            'day_filter',
          ];
        else if (this.selectedReport.id === 8)
          return [
            'channel_id',
            'date_from',
            'date_to',
            'target_audience_id',
            'commercial_type_id',
            'day_interval',
            'time_start_at',
            'time_end_at',
            'program_releases_names',
          ];
        else if (this.selectedReport.id === 9)
          return ['channel_ids', 'date_from', 'date_to', 'rating_type', 'target_audience_id', 'commercial_type_id', 'day_filter'];
        else if (this.selectedReport.id === 10) return ['channel_ids', 'date_from', 'date_to', 'agency_ids', 'advertiser_ids'];
        else if (this.selectedReport.id === 11)
          return ['channel_ids', 'date_from', 'date_to', 'agency_ids', 'advertiser_ids', 'commercial_type_id', 'mmr_2024_plus'];
        else if (this.selectedReport.id === 12) return ['year_id', 'subchannel_ids', 'commercial_type_id', 'target_audience_id', 'include_special_discounts'];
      } else {
        return [];
      }
    },
    checkNotValid() {
      // Check for required selects
      if (this.selectedReport) {
        if (this.selectedReport.id === 0 || this.selectedReport.id === 2 || this.selectedReport.id === 3 || this.selectedReport.id === 10) {
          if (this.selects.date_from && this.selects.date_to) return false;
          else return true;
        } else if (this.selectedReport.id === 1) {
          if (
            this.selects.date_from &&
            this.selects.date_to &&
            // this.selects.channels.length !== 0 &&
            this.selects.agencies.length !== 0 &&
            this.selects.advertisers.length !== 0
          )
            return false;
          return true;
        } else if (this.selectedReport.id === 4 || this.selectedReport.id === 12 || this.selectedReport.id === 5 || this.selectedReport.id === 6) {
          if (this.selects.year) return false;
          return true;
        } else if (this.selectedReport.id === 7) {
          if (this.selects.date_from && this.selects.date_to && this.selects.time_interval && this.selects.day_interval)
            return false;
          return true;
        } else if (this.selectedReport.id === 8) {
          if (
            this.selects.date_from &&
            this.selects.date_to &&
            this.selects.channel &&
            this.selects.target &&
            this.selects.day_interval &&
            this.selects.time_start_at &&
            this.selects.time_end_at
          )
            return false;
          return true;
        } else if (this.selectedReport.id === 9) {
          if (this.selects.date_from && this.selects.date_to && this.selects.rating_type) return false;
        }
      } else {
        return true;
      }
    },
    showDisabledExplanation() {
      if (this.selectedReport) {
        if (
          (this.selectedReport.id === 0 ||
            this.selectedReport.id === 2 ||
            this.selectedReport.id === 3 ||
            this.selectedReport.id === 9 ||
            this.selectedReport.id === 10 ||
            this.selectedReport.id === 11) &&
          this.checkNotValid
        )
          return this.$i18n.t('table.selectDatesFirst');
        else if (this.selectedReport.id === 1 && this.checkNotValid) return this.$i18n.t('reports.selectDatesAgencyAdvertiserAndChannelFirst');
        else if (
          (this.selectedReport.id === 4 || this.selectedReport.id === 12 || this.selectedReport.id === 5 || this.selectedReport.id === 6) &&
          this.checkNotValid
        )
          return this.$i18n.t('reports.selectYearFirst');
        else if (this.selectedReport.id === 7 && this.checkNotValid) return this.$i18n.t('reports.selectDatesChannelDayIntervalTimeIntervalFirst');
        else if (this.selectedReport.id === 8 && this.checkNotValid) return this.$i18n.t('reports.selectDatesChannelTargetDayIntervalPeriodFirst');
        else return '';
      }
    },
  },
  watch: {},
  beforeCreate() {
    if (!this.$store.hasModule('reports')) this.$store.registerModule('reports', reports);
  },
  created() {
    document.title = this.$i18n.t('broadcastSideBar.reports') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getData();
  },
  destroyed() {
    this.$store.commit('clearAdvertisersList');
    this.$store.commit('clearTargetsList');
    this.$store.commit('clearBrandsList');
    this.$store.commit('clearProjects');
    if (!module.hot && this.$store.hasModule('reports')) this.$store.unregisterModule('reports'); // don't unregister when HMR
  },
  methods: {
    async getEqualsSettings() {
      //get sh settings
      if (this.FEATURES.EQUALS) {
        if (
          !(
            this.sh_settings &&
            this.sh_settings[0]?.year_id == this.selects.date_from.slice(0, 4) &&
            this.selectedSaleshouse.id == this.sh_settings.saleshouse_id
          )
        )
          this.$store.dispatch('GET_SALEHOUSES_SETTINGS', {
            //    id: 123,
            'filter[saleshouse_id]': this.selectedSaleshouse.id,
            'filter[year_id]': this.selects.date_from.slice(0, 4),
          });
        this.is_equals_enabled = this.sh_settings[0]?.is_equals_enabled ?? false;
      } else {
        this.is_equals_enabled = false;
      }
    },
    selectedDateFrom() {
      // Rewrite date TO if it is lower than FROM
      if (new Date(this.selects.date_from) > new Date(this.selects.date_to)) {
        this.selects.date_to = this.selects.date_from;
      }
      this.loadProgramReleases();
      this.updateQuery();
      if (this.selectedReport?.id == 1) {
        this.getEqualsSettings();
      }
    },
    selectedFilter() {
      this.loadProgramReleases();
      this.updateQuery();
    },

    async getData() {
      this.loading = true;
      try {
        await Promise.all([
          !this.channels || this.channels.length < 1 ? this.$store.dispatch('GET_CHANNELS', { per_page: 1000 }) : undefined,
          !this.measurementsList || this.measurementsList.length < 1 ? this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 }) : undefined,
        ]);
      } catch (e) {
        console.log(e);
      }

      this.filterChannelsList = this.channels; //[{ name: this.$i18n.t('table.all'), id: null }, ...this.channels];

      await this.loadProps();
      this.loading = false;
    },

    async selectChannels() {
      if (this.loading) return;
      // if All is selected - reset array
      const ch_ids = this.selects.channels.map((e) => e.id);
      if (ch_ids.includes(null)) {
        this.selects.channels = [];
        await this.selectChannels();
        return;
      }
      this.loadProgramReleases();
      this.updateQuery();
    },

    async selectSubchannels() {
      if (this.loading) return;
      // if All is selected - reset array
      const subchannels = this.selects.subchannels.map((e) => e.id);
      if (subchannels.includes(null)) {
        this.selects.subchannels = [];
        return;
      }
      this.updateQuery();
    },

    async selectAgency(newValue) {
      if (!this.loading) {
        if (this.selects.agency?.id) await this.loadAdvertisers();
        else this.filterAdvertisersList = [];
        if (this.selects.advertiser?.agency_id !== this.selects.agency?.id || !newValue) {
          this.selects.advertiser = '';
          this.selects.brand = '';
          this.$store.commit('clearBrandsList');
        }
        this.selects.advertisers = [];
        this.updateQuery();
      }
    },
    async selectAgencies() {
      if (this.loading) return;
      // if All is selected - reset array
      const ag_ids = this.selects.agencies.map((e) => e.id);
      if (ag_ids.includes(null)) {
        this.selects.agencies = [];
        await this.selectAgencies();
        return;
      }
      // From n selected Agencies
      await this.loadAdvertisers();
      this.selects.advertiser = '';
      this.selects.advertisers = [];
      this.selects.brand = '';
      this.selects.brands = [];
      this.$store.commit('clearBrandsList');
      this.updateQuery();
    },

    asyncFindAdvertisers: debounce(function (query) {
      if (query && this.selects.agencies.length === 0) {
        this.loadAdvertisers(query);
      }
    }, 700),

    async selectAdvertiser() {
      if (!this.loading) {
        if (this.selects.advertiser?.id) await this.loadBrandsList();
        if (this.selects.brand?.advertiser_id !== this.selects.advertiser?.id) this.selects.brand = '';
        this.updateQuery();
      }
    },

    async selectAdvertisers() {
      if (this.loading) return;
      // if All is selected - reset array
      const adv_ids = this.selects.advertisers.map((e) => e.id);
      if (adv_ids.includes(null)) {
        this.selects.advertisers = [];
        await this.selectAdvertisers();
        return;
      }
      await this.loadBrandsList();
      this.selects.brand = '';
      this.selects.brands = [];
      this.updateQuery();
    },

    async loadAdvertisers(query) {
      if (
        (this.selectedReport?.id === 0 ||
          this.selectedReport?.id === 1 ||
          this.selectedReport?.id === 5 ||
          this.selectedReport?.id === 6 ||
          this.selectedReport?.id === 10 ||
          this.selectedReport?.id === 11) &&
        this.selects.agencies.map((ag) => ag.id).length === 0 &&
        !query
      ) {
        this.filterAdvertisersList = [];
        this.filterBrandsList = [];
        return;
      }
      await this.$store.dispatch('GET_ADVERTISERS', {
        'filter[name]': query || undefined,
        'filter[agency_id]':
          this.selectedReport?.id === 0 ||
          this.selectedReport?.id === 1 ||
          this.selectedReport?.id === 5 ||
          this.selectedReport?.id === 6 ||
          this.selectedReport?.id === 10 ||
          this.selectedReport?.id === 11
            ? this.selects.agencies.map((ag) => ag.id)
            : this.selects.agency?.id,
        per_page: 2000,
      });
      this.filterAdvertisersList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.advertisersList];
    },

    async selectBrands() {
      if (this.loading) return;
      const br_ids = this.selects.brands.map((e) => e.id);
      if (br_ids.includes(null)) {
        this.selects.brands = [];
      }
      this.updateQuery();
    },

    asyncFindBrands: debounce(function (query) {
      if (query && this.selects.advertisers.length === 0) {
        this.loadBrandsList(query);
      }
    }, 700),

    async loadBrandsList(query) {
      if (
        (this.selectedReport?.id === 0 || this.selectedReport?.id === 1 || this.selectedReport?.id === 10 || this.selectedReport?.id === 11) &&
        this.selects.advertisers.map((adv) => adv.id).length === 0 &&
        !query
      ) {
        this.filterBrandsList = [];
        return;
      }
      await this.$store.dispatch('GET_BRANDS', {
        'filter[name]': query || undefined,
        'filter[advertiser_id]':
          this.selectedReport?.id === 0 || this.selectedReport?.id === 1 || this.selectedReport?.id === 10 || this.selectedReport?.id === 11
            ? this.selects.advertisers.map((adv) => adv.id)
            : this.selects.advertiser?.id,
        per_page: 2000,
      });
      if ((this.selectedReport?.id === 0 || this.selectedReport?.id === 1) && this.brandsList)
        this.filterBrandsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.brandsList];
      else this.filterBrandsList = this.brandsList || [];
    },

    async selectedMeasurement() {
      if (this.selects.measurement) await this.loadTargetsList();
      else this.$store.commit('clearTargetsList');
      if (this.selects.target?.measurement_company_id !== this.selects.measurement?.id) this.selects.target = '';
      this.updateQuery();
    },

    async loadTargetsList() {
      await this.$store.dispatch('GET_TARGET', { 'filter[measurement_company_id]': this.selects.measurement?.id, per_page: 1000 });
    },

    async selectedYear() {
      if (this.selectedReport?.id === 5 || this.selectedReport?.id === 6) await this.loadProjectsList();
      this.updateQuery();
    },

    async loadProjectsList() {
      await this.$store.dispatch('GET_PROJECTS', {
        per_page: 1000,
        'filter[agency_id]': this.selects.agency?.id,
        'filter[year_id]': this.selects.year ? this.selects.year.id : new Date().getFullYear(),
        'filter[commercial_type_id]': this.selects.commercial?.id,
      });
      this.filterProjectsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.projects];
    },

    async loadSubchannelsList() {
      await this.$store.dispatch('GET_SUBCHANNELS', {
        per_page: 1000,
      });
      this.filterSubchannelsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.subchannels];
    },

    async loadProgramReleases() {
      if (this.selectedReport?.id !== 8) return;
      if (!(this.selects.channel && this.selects.date_from && this.selects.date_to)) return;
      await this.$store.dispatch('GET_PROGRAM_RELEASES_DISTINCT_NAMES', {
        'filter[channel_id]': this.selects.channel.id,
        'filter[broadcast_day_from]': this.selects.date_from,
        'filter[broadcast_day_to]': this.selects.date_to,
        per_page: 2000,
      });
    },

    async loadReport() {
      if (this.selectedReport) {
        if (this.selectedReport.id === 0) {
          if (this.FEATURES.EQUALS) this.getEqualsSettings();
          let data = {
            'filter[agency_id]': this.selects.agencies.map((ag) => ag.id),
            'filter[advertiser_id]': this.selects.advertisers.map((adv) => adv.id),
            'filter[brand_id]': this.selects.brands.map((b) => b.id),
            'filter[date_from]': this.selects.date_from,
            'filter[date_to]': this.selects.date_to,
            'filter[channel_ids]': this.selects.channels.map((c) => c.id),

            'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
              ? this.selects.include_special_discounts
                ? 1
                : 0
              : undefined,
            'filter[grouping_type]': this.selects.grouping_type,
            'filter[include_discounts]': this.selects.with_discount_details ? 1 : 0,
          };
          if (this.selects.spotFactTime != 'all') {
            data['filter[spots_fact_bound]'] = this.selects.spotFactTime;
          }
          await this.$store.dispatch('GET_REPORT_COSTS', data);
        } else if (this.selectedReport.id === 1) {
          if (this.FEATURES.EQUALS) this.getEqualsSettings();
          await this.$store.dispatch('GET_REPORT_BROADCAST_REFERENCE', {
            'filter[agency_id]': this.selects.agencies.map((ag) => ag.id),
            'filter[advertiser_id]': this.selects.advertisers.map((adv) => adv.id),
            'filter[brand_id]': this.selects.brands.map((b) => b.id),
            'filter[date_from]': this.selects.date_from,
            'filter[date_to]': this.selects.date_to,
            'filter[channel_id]': this.selects.channels.map((c) => c.id),
            'filter[with_wgrp]': 1,
            'filter[grouping_type]': this.selects.grouping_type,
          });
        } else if (this.selectedReport.id === 2) {
          if (this.FEATURES.EQUALS) this.getEqualsSettings();
          await this.$store.dispatch('GET_REPORT_CLIENTS_ADVERTISERS', {
            'filter[agency_id]': this.selects.agency?.id,
            'filter[advertiser_id]': this.selects.advertiser?.id,
            'filter[brand_id]': this.selects.brand?.id,
            'filter[date_from]': this.selects.date_from,
            'filter[date_to]': this.selects.date_to,
            'filter[channel_id]': this.selects.channel?.id,
            'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
              ? this.selects.include_special_discounts
                ? 1
                : 0
              : undefined,
          });
        } else if (this.selectedReport.id === 3) {
          await this.$store.dispatch('GET_REPORT_OBLIGATION_EXECUTIONS', {
            'filter[agency_id]': this.selects.agency?.id,
            'filter[advertiser_id]': this.selects.advertiser?.id,
            'filter[brand_id]': this.selects.brand?.id,
            'filter[date_from]': this.selects.date_from,
            'filter[date_to]': this.selects.date_to,
            'filter[channel_id]': this.selects.channels.map((c) => c.id),
            'filter[placement_type_id]': this.selects.type?.id,
            'filter[commercial_type_id]': this.selects.commercial?.id,
            'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
              ? this.selects.include_special_discounts
                ? 1
                : 0
              : undefined,
            'filter[grouping_type]': this.selects.grouping_type,
          });
        } else if (this.selectedReport.id === 4) {
          await this.$store.dispatch('GET_REPORT_SALES_PLAN_EXECUTIONS', {
            'filter[year_id]': this.selects.year?.id,
            'filter[channel_ids]': this.selects.channels.map((c) => c.id),
            'filter[commercial_type_id]': this.selects.commercial?.id,
            'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
              ? this.selects.include_special_discounts
                ? 1
                : 0
              : undefined,
          });
        } else if (this.selectedReport.id === 12) {
          await this.$store.dispatch('GET_REPORT_SALES_PLAN_EXECUTIONS_TV_NETWORK', {
            'filter[year_id]': this.selects.year?.id,
            'filter[sub_channel_ids]': this.selects.subchannels.map((c) => c.id),
            'filter[commercial_type_id]': this.selects.commercial?.id,
            'filter[target_audience_id]': this.selects.target?.id,
            'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
              ? this.selects.include_special_discounts
                ? 1
                : 0
              : undefined,
          });
        } else if (this.selectedReport.id === 5) {
          await this.$store.dispatch('GET_REPORT_PROJECTS', {
            'filter[year_id]': this.selects.year?.id,
            'filter[agency_id]': this.selects.agencies.map((c) => c.id),
            'filter[advertiser_id]': this.selects.advertisers.map((c) => c.id),
            'filter[project_id]': this.selects.project?.id,
            'filter[channel_ids]': this.selects.channels.map((c) => c.id),
            'filter[placement_type_id]': this.selects.type?.id,
            'filter[commercial_type_id]': this.selects.commercial?.id,
            'filter[data_type]': this.selects.inventory_or_budget,
            'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
              ? this.selects.include_special_discounts
                ? 1
                : 0
              : undefined,
          });
        } else if (this.selectedReport.id === 6) {
          await this.$store.dispatch('GET_REPORT_PROJECTS_SHORT', {
            'filter[year_id]': this.selects.year?.id,
            'filter[agency_id]': this.selects.agency?.id,
            'filter[project_id]': this.selects.project?.id,
            'filter[channel_ids]': this.selects.channels.map((c) => c.id),
            'filter[placement_type_id]': this.selects.type?.id,
            'filter[commercial_type_id]': this.selects.commercial?.id,
            'filter[data_type]': this.selects.inventory_or_budget,
            'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
              ? this.selects.include_special_discounts
                ? 1
                : 0
              : undefined,
          });
        } else if (this.selectedReport.id === 7) {
          await this.$store.dispatch('GET_REPORT_INVENTORY', {
            'filter[date_from]': this.selects.date_from,
            'filter[date_to]': this.selects.date_to,
            'filter[channel_id]': this.selects.channels.map((c) => c.id),
            'filter[commercial_type_id]': this.selects.commercial?.id,
            'filter[target_audience_id]': this.selects.target?.id,
            'filter[time_interval]': this.selects.time_interval,
            'filter[day_interval]': this.selects.day_interval,
            'filter[time_filter]': this.selects.time_filter,
            'filter[day_filter]': this.selects.day_filter,
            //'filter[grouping_type]': this.selects.grouping_type,
          });
        } else if (this.selectedReport.id === 8) {
          const selectedReleases = this.selects.program_releases_names.map((r) => r.name);
          await this.$store.dispatch('GET_REPORT_PROGRAMS', {
            'filter[broadcast_day_from]': this.selects.date_from,
            'filter[broadcast_day_to]': this.selects.date_to,
            'filter[channel_id]': this.selects.channel?.id,
            'filter[commercial_type_id]': this.selects.commercial?.id,
            'filter[target_audience_id]': this.selects.target?.id,
            'filter[day_interval]': this.selects.day_interval,
            'filter[start_interval]': this.selects.time_start_at,
            'filter[end_interval]': this.selects.time_end_at,
            'filter[program_release_names]': selectedReleases,
          });
        } else if (this.selectedReport.id === 9) {
          await this.$store.dispatch('GET_REPORT_INVENTORY_EXTENDED', {
            'filter[channel_ids]': this.selects.channels.map((c) => c.id),
            'filter[date_from]': this.selects.date_from,
            'filter[date_to]': this.selects.date_to,
            'filter[rating_type]': this.selects.rating_type,
            'filter[target_audience_id]': this.selects.target?.id,
            'filter[commercial_type_id]': this.selects.commercial?.id,
            'filter[day_filter]': this.selects.day_filter,
          });
        } else if (this.selectedReport.id === 10) {
          if (this.FEATURES.EQUALS) this.getEqualsSettings();
          await this.$store.dispatch('GET_REPORT_CATEGORY_SUMMARY', {
            'filter[channel_ids]': this.selects.channels.map((c) => c.id),
            'filter[date_from]': this.selects.date_from,
            'filter[date_to]': this.selects.date_to,
            'filter[agency_ids]': this.selects.agencies.map((ag) => ag.id),
            'filter[advertiser_ids]': this.selects.advertisers.map((a) => a.id),
          });
        } else if (this.selectedReport.id === 11) {
          await this.$store.dispatch('GET_REPORT_MMR_KAZAKHSTAN', {
            'filter[channel_ids]': this.selects.channels.map((c) => c.id),
            'filter[date_from]': this.selects.date_from,
            'filter[date_to]': this.selects.date_to,
            'filter[agency_ids]': this.selects.agencies.map((ag) => ag.id),
            'filter[advertiser_ids]': this.selects.advertisers.map((a) => a.id),
            'filter[commercial_type_id]': this.selects.commercial?.id,
            'filter[pre2024]': this.selects.mmr_2024_plus ? 0 : 1,
          });
        }
      }
      this.updateQuery();
    },

    async loadDataAndOpenCPModal() {
      await this.loadReport();
      this.$bvModal.show('edit-sales-plan-executions-modal');
    },

    updateQuery() {
      setTimeout(() => {
        const releases = this.selects.program_releases_names.map((r) => r.name).toString();
        if (
          this.$route.query.commercial_type_id != this.selects.commercial?.id ||
          this.$route.query.agency_id != this.selects.agency?.id ||
          this.$route.query.agency_ids != this.selects.agencies.map((a) => a.id).toString() ||
          this.$route.query.advertiser_id != this.selects.advertiser?.id ||
          this.$route.query.advertiser_ids != this.selects.advertisers.map((a) => a.id).toString() ||
          this.$route.query.brand_id != this.selects.brand?.id ||
          this.$route.query.brand_ids != this.selects.brands.map((a) => a.id).toString() ||
          this.$route.query.date_from != this.selects.date_from ||
          this.$route.query.date_to != this.selects.date_to ||
          this.$route.query.channel_id != this.selects.channel?.id ||
          this.$route.query.channel_ids != this.selects.channels.map((c) => c.id).toString() ||
          this.$route.query.subchannel_ids != this.selects.subchannels.map((c) => c.id).toString() ||
          this.$route.query.placement_type_id != this.selects.type?.id ||
          this.$route.query.measurement_id != this.selects.measurement?.id ||
          this.$route.query.target_id != this.selects.target?.id ||
          this.$route.query.report_type != this.selectedReport?.id ||
          this.$route.query.with_wgrp !=
            (this.selects.with_wgrp !== undefined && this.selects.with_wgrp !== '' ? this.selects.with_wgrp.toString() : this.selects.with_wgrp) ||
          this.$route.query.year != this.selects.year?.id ||
          this.$route.query.project_id != this.selects.project?.id ||
          this.$route.query.time_interval != this.selects.time_interval ||
          this.$route.query.inventory_or_budget != this.selects.inventory_or_budget ||
          this.$route.query.day_interval != this.selects.day_interval ||
          this.$route.query.day_filter != this.selects.day_filter ||
          this.$route.query.time_filter != this.selects.time_filter ||
          this.$route.query.time_start_at != this.selects.time_start_at ||
          this.$route.query.time_end_at != this.selects.time_end_at ||
          this.$route.query.program_releases_names != releases ||
          this.$route.query.rating_type != this.selects.rating_type ||
          this.$route.query.grouping_type != this.selects.grouping_type ||
          this.$route.query.spot_fact_time != this.selects.spotFactTime ||
          this.$route.query.with_discount_details !=
            (this.selects.with_discount_details !== undefined && this.selects.with_discount_details !== ''
              ? this.selects.with_discount_details.toString()
              : this.selects.with_discount_details)
        ) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              commercial_type_id: this.selects.commercial?.id,
              agency_id: this.selects.agency?.id,
              agency_ids: this.selects.agencies.map((a) => a.id).toString(),
              advertiser_id: this.selects.advertiser?.id,
              advertiser_ids: this.selects.advertisers.map((a) => a.id).toString(),
              brand_id: this.selects.brand?.id,
              brand_ids: this.selects.brands.map((a) => a.id).toString(),
              date_from: this.selects.date_from,
              date_to: this.selects.date_to,
              channel_id: this.selects.channel?.id,
              channel_ids: this.selects.channels.map((c) => c.id).toString(),
              subchannel_ids: this.selects.subchannels.map((c) => c.id).toString(),
              placement_type_id: this.selects.type?.id,
              measurement_id: this.selects.measurement?.id,
              target_id: this.selects.target?.id,
              report_type: this.selectedReport?.id,
              with_wgrp: this.selects.with_wgrp,
              year: this.selects.year?.id,
              time_interval: this.selects.time_interval,
              inventory_or_budget: this.selects.inventory_or_budget,
              project_id: this.selects.project?.id,
              day_interval: this.selects.day_interval,
              time_filter: this.selects.time_filter,
              day_filter: this.selects.day_filter,
              time_start_at: this.selects.time_start_at,
              time_end_at: this.selects.time_end_at,
              program_releases_names: releases,
              rating_type: this.selects.rating_type,
              grouping_type: this.selects.grouping_type,
              spot_fact_time: this.selects.spotFactTime,
              with_discount_details: this.selects.with_discount_details,
            },
          });
        }
      }, 50);
    },

    async loadProps() {
      if (this.report_type && /^\d+$/.test(this.report_type)) {
        this.selectedReport = this.reportsList.find((el) => el.id === +this.report_type) || '';
        if (this.selectedReport) {
          if (this.selectedReport.id === 12) {
            await Promise.all([
              !this.commercialType || this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined,
              this.yearsList.length < 1 ? this.$store.dispatch('GET_YEAR') : undefined,
            ]);
            await this.loadSubchannelsList();
            if (this.year && /\d{4}/.test(this.year)) {
              this.selects.year = this.yearsList.find((el) => el.id === +this.year);
            }
            if (!this.selects.year) this.selects.year = this.yearsList.find((el) => el.id === +new Date().getFullYear());
            if (this.commercial_type_id && /^\d+$/.test(this.commercial_type_id)) {
              this.selects.commercial = this.commercialType.find((el) => el.id === +this.commercial_type_id) || '';
            }
          }
          if (
            this.selectedReport.id === 3 ||
            this.selectedReport.id === 4 ||
            this.selectedReport.id === 5 ||
            this.selectedReport.id === 6 ||
            this.selectedReport.id === 7 ||
            this.selectedReport.id === 8 ||
            this.selectedReport.id === 9
          ) {
            await Promise.all([
              !this.commercialType || this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined,
              !this.placementType || this.placementType.length < 1 ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 }) : undefined,
              this.yearsList.length < 1 ? this.$store.dispatch('GET_YEAR') : undefined,
            ]);
            if (this.year && /\d{4}/.test(this.year)) {
              this.selects.year = this.yearsList.find((el) => el.id === +this.year);
            }
            if (!this.selects.year) this.selects.year = this.yearsList.find((el) => el.id === +new Date().getFullYear());
            if (this.commercial_type_id && /^\d+$/.test(this.commercial_type_id)) {
              this.selects.commercial = this.commercialType.find((el) => el.id === +this.commercial_type_id) || '';
            }
            if (this.placement_type_id && /^\d+$/.test(this.placement_type_id)) {
              this.selects.type = this.placementType.find((el) => el.id === +this.placement_type_id) || '';
            }
          }

          if (this.selectedReport?.id === 11) {
            await Promise.all([!this.commercialType || this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined]);
            if (this.commercial_type_id && /^\d+$/.test(this.commercial_type_id)) {
              this.selects.commercial = this.commercialType.find((el) => el.id === +this.commercial_type_id) || '';
            }
          }

          if (this.selectedReport.id === 5 || this.selectedReport.id === 6) {
            await this.loadProjectsList();
            if (this.project_id && /^\d+$/.test(this.project_id)) this.selects.project = this.projects.find((p) => p.id === +this.project_id) || '';
          }

          if (this.agency_id && /^\d+$/.test(this.agency_id)) {
            this.selects.agency = this.agenciesList.find((el) => el.id === +this.agency_id) || '';
            if (this.selects.agency) {
              await this.loadAdvertisers();
              if (this.advertiser_id && /^\d+$/.test(this.advertiser_id)) {
                this.selects.advertiser = this.advertisersList.find((el) => el.id === +this.advertiser_id) || '';
                if (this.selects.advertiser) {
                  await this.loadBrandsList();
                  if (this.brand_id && /^\d+$/.test(this.brand_id)) {
                    this.selects.brand = this.brandsList.find((el) => el.id === +this.brand_id) || '';
                  }
                }
              }
              if (this.advertiser_ids && /^[\d,]{1,}$/.test(this.advertiser_ids)) {
                const advPropArray = this.advertiser_ids.split(',').map((e) => +e);
                this.selects.advertisers = this.advertisersList.filter((el) => advPropArray.includes(el.id));
              }
            }
          }

          // restore new multifilters
          if (this.selectedReport.id === 0 || this.selectedReport.id === 1 || this.selectedReport.id === 10 || this.selectedReport.id === 11) {
            if (this.agency_ids && /^[\d,]{1,}$/.test(this.agency_ids)) {
              const ag_ids = this.agency_ids.split(',').map((e) => +e);
              this.selects.agencies = this.filterAgenciesList.filter((el) => ag_ids.includes(el.id));
              if (this.selects.agencies.length > 0) await this.loadAdvertisers();
            }
            if (this.advertiser_ids && /^[\d,]{1,}$/.test(this.advertiser_ids)) {
              const adv_ids = this.advertiser_ids.split(',').map((e) => +e);
              if (this.filterAdvertisersList.length > 0) this.selects.advertisers = this.filterAdvertisersList.filter((el) => adv_ids.includes(el.id));
              else {
                // restore filter without selected parents
                await getAdvertisers({
                  'filter[id]': adv_ids,
                  'filter[agency_id]': this.selects.agencies.map((a) => a.id),
                  per_page: 1000,
                })
                  .then((res) => {
                    const advList = res.data?.data || [];
                    this.selects.advertisers = advList.filter((el) => adv_ids.includes(el.id));
                  })
                  .catch((e) => {});
              }
              if (this.selects.advertisers.length > 0) await this.loadBrandsList();
            }

            if (this.brand_ids && /^[\d,]{1,}$/.test(this.brand_ids)) {
              const b_ids = this.brand_ids.split(',').map((e) => +e);
              if (this.filterBrandsList.length > 0) this.selects.brands = this.filterBrandsList.filter((el) => b_ids.includes(el.id));
              else {
                // restore filter without selected parents
                await getBrands({
                  'filter[id]': b_ids,
                  'filter[brand_id]': this.selects.brands.map((a) => a.id),
                  per_page: 1000,
                })
                  .then((res) => {
                    const brandsList = res.data?.data || [];
                    this.selects.brands = brandsList.filter((el) => b_ids.includes(el.id));
                  })
                  .catch((e) => {});
              }
            }
          }

          if (this.date_from && /\d{4}-\d{2}-\d{2}/.test(this.date_from)) this.selects.date_from = this.date_from;
          if (this.date_to && /\d{4}-\d{2}-\d{2}/.test(this.date_to)) this.selects.date_to = this.date_to;
          if (this.channel_id && /^\d+$/.test(this.channel_id)) this.selects.channel = this.channels.find((el) => el.id === +this.channel_id) || '';
          if (this.channel_ids && /^[\d,]{1,}$/.test(this.channel_ids)) {
            const channelsPropArray = this.channel_ids.split(',').map((e) => +e);
            this.selects.channels = this.channels.filter((el) => channelsPropArray.includes(el.id));
          }
          if (this.subchannel_ids && /^[\d,]{1,}$/.test(this.subchannel_ids)) {
            const subchannelsPropArray = this.subchannel_ids.split(',').map((e) => +e);
            this.selects.subchannels = this.subchannels.filter((el) => subchannelsPropArray.includes(el.id));
          }
          if (this.with_wgrp) this.selects.with_wgrp = this.with_wgrp === 'true';
          //TODO: need a backend permission for this toggle. For now front-only solution
          if (this.with_discount_details && (this.isAdmin || this.isSaleshouseManager || this.isChiefSaleshouse))
            this.selects.with_discount_details = this.with_discount_details === 'true';
          if (this.measurement_id && /^\d+$/.test(this.measurement_id)) {
            this.selects.measurement = this.measurementsList.find((el) => el.id === +this.measurement_id) || '';
            if (this.selects.measurement) {
              await this.loadTargetsList();
              if (this.target_id && /^\d+$/.test(this.target_id)) {
                this.selects.target = this.targetList.find((el) => el.id === +this.target_id) || '';
              }
            }
          }

          if (this.time_interval) this.selects.time_interval = this.time_interval;
          if (this.inventory_or_budget) this.selects.inventory_or_budget = this.inventory_or_budget;
          if (this.day_interval) this.selects.day_interval = this.day_interval;
          if (this.day_filter) this.selects.day_filter = this.day_filter;
          if (this.time_filter) this.selects.time_filter = this.time_filter;
          if (this.time_start_at && /\d{4,}/.test(this.time_start_at)) this.selects.time_start_at = +this.time_start_at;
          if (this.time_end_at && /\d{4,}/.test(this.time_end_at)) this.selects.time_end_at = +this.time_end_at;
          if (this.rating_type === 'plan_fact' || this.rating_type === 'plan' || this.rating_type === 'fact') this.selects.rating_type = this.rating_type;
          if (this.grouping_type) this.selects.grouping_type = this.grouping_type;

          this.selects.spotFactTime = this.spotFactTime == null || this.spotFactTime == '' ? 'only' : this.spotFactTime;

          if (this.selectedReport.id === 8) {
            await this.loadProgramReleases();
            if (this.program_releases_names) {
              const releasesArray = this.program_releases_names.split(',');
              releasesArray.forEach((r) => {
                const tempRelease = this.programReleasesDistinctNames.find((pr) => pr.name === r);
                if (tempRelease) this.selects.program_releases_names.push(tempRelease);
              });
            }
          }
          this.createFiltersList();
        }
        //if (!this.checkNotValid) this.loadReport();
        this.updateQuery();
      }
    },

    createFiltersList() {
      if (
        this.selectedReport?.id === 3 ||
        this.selectedReport?.id === 2 ||
        this.selectedReport?.id === 4 ||
        this.selectedReport?.id === 5 ||
        this.selectedReport?.id === 6 ||
        this.selectedReport?.id === 1 ||
        this.selectedReport?.id === 7 ||
        this.selectedReport?.id === 12
      ) {
        this.filterChannelsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.channels];
      } else {
        if (this.selects.channel?.id === null) this.selects.channel = ''; // reset selected channel "All"
        this.filterChannelsList = this.channels;
      }

      this.filterAgenciesList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.agenciesList];
    },

    async resetTables() {
      if (this.selectedReport?.id !== 12) {
        await Promise.all([!this.agenciesList || this.agenciesList.length < 1 ? this.$store.dispatch('GET_AGENCIES', { per_page: 1000 }) : undefined]);
        this.filterAgenciesList = this.agenciesList; // [{ name: this.$i18n.t('table.all'), id: null }, ...this.agenciesList];
      } else {
        await Promise.all([
          !this.commercialType || this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined,
          this.yearsList.length < 1 ? this.$store.dispatch('GET_YEAR') : undefined,
        ]);
        if (!this.selects.year) this.selects.year = this.yearsList.find((el) => el.id === +new Date().getFullYear());
        await this.loadSubchannelsList();
      }
      if (this.selectedReport?.id === 3) {
        await Promise.all([
          !this.commercialType || this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined,
          !this.placementType || this.placementType.length < 1 ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 }) : undefined,
        ]);
      } else if (this.selectedReport?.id === 4) {
        await Promise.all([
          !this.commercialType || this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined,
          this.yearsList.length < 1 ? this.$store.dispatch('GET_YEAR') : undefined,
        ]);
        if (!this.selects.year) this.selects.year = this.yearsList.find((el) => el.id === +new Date().getFullYear());
      } else if (this.selectedReport?.id === 5 || this.selectedReport?.id === 6) {
        await Promise.all([
          !this.commercialType || this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined,
          !this.placementType || this.placementType.length < 1 ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 }) : undefined,
          this.yearsList.length < 1 ? this.$store.dispatch('GET_YEAR') : undefined,
        ]);
        if (!this.selects.year) this.selects.year = this.yearsList.find((el) => el.id === +new Date().getFullYear());
        await this.loadProjectsList();
      } else if (this.selectedReport?.id === 7 || this.selectedReport?.id === 8 || this.selectedReport?.id === 9) {
        if (!this.commercialType || this.commercialType.length < 1) await this.$store.dispatch('GET_COMMERCIAL_TYPE');
        if (this.selectedReport?.id === 8) await this.loadProgramReleases();
      } else if (this.selectedReport?.id === 11) {
        if (!this.commercialType || this.commercialType.length < 1) await this.$store.dispatch('GET_COMMERCIAL_TYPE');
      }

      this.createFiltersList();

      this.$store.commit('clearReportBroadcastReference');
      this.$store.commit('clearReportCosts');
      this.$store.commit('clearReportClientsAdvertisers');
      this.$store.commit('clearReportObligationExecutions');
      this.$store.commit('clearReportSalesPlanExecutions');
      this.$store.commit('clearReportSalesPlanExecutionsTVNetwork');
      this.$store.commit('clearReportInventory');
      this.$store.commit('clearReportByProjects');
      this.$store.commit('clearReportByProjectsShort');
      this.$store.commit('clearReportPrograms');
      this.$store.commit('clearReportInventoryExtended');
      this.$store.commit('clearReportCategorySummary');
      this.$store.commit('clearReportMMRKazakhstan');
      this.updateQuery();
    },

    async exportReport() {
      if (this.selectedReport) {
        this.processing = true;
        if (this.selectedReport.id === 0) {
          let data = {
            format: 'xlsx',
            'filter[agency_id]': this.selects.agencies.map((ag) => ag.id),
            'filter[advertiser_id]': this.selects.advertisers.map((adv) => adv.id),
            'filter[brand_id]': this.selects.brands.map((b) => b.id),
            'filter[date_from]': this.selects.date_from,
            'filter[date_to]': this.selects.date_to,
            'filter[channel_ids]': this.selects.channels.map((c) => c.id),
            'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
              ? this.selects.include_special_discounts
                ? 1
                : 0
              : undefined,
            'filter[grouping_type]': this.selects.grouping_type,
            'filter[include_discounts]': this.selects.with_discount_details ? 1 : 0,
          };
          if (this.selects.spotFactTime != 'all') {
            data['filter[spots_fact_bound]'] = this.selects.spotFactTime;
          }
          await this.$store.dispatch('GET_EXPORT_REPORT_COSTS', {
            data: data,
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 1) {
          await this.$store.dispatch('GET_EXPORT_REPORT_BROADCAST_REFERENCE', {
            data: {
              format: 'xlsx',
              'filter[agency_id]': this.selects.agencies.map((ag) => ag.id),
              'filter[advertiser_id]': this.selects.advertisers.map((adv) => adv.id),
              'filter[brand_id]': this.selects.brands.map((b) => b.id),
              'filter[date_from]': this.selects.date_from,
              'filter[date_to]': this.selects.date_to,
              'filter[channel_id]': this.selects.channels.map((c) => c.id),
              'filter[with_wgrp]': this.selects.with_wgrp ? 1 : 0,
              'filter[grouping_type]': this.selects.grouping_type,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 2) {
          await this.$store.dispatch('GET_EXPORT_REPORT_CLIENTS_ADVERTISERS', {
            data: {
              format: 'xlsx',
              'filter[agency_id]': this.selects.agency?.id,
              'filter[advertiser_id]': this.selects.advertiser?.id,
              'filter[brand_id]': this.selects.brand?.id,
              'filter[date_from]': this.selects.date_from,
              'filter[date_to]': this.selects.date_to,
              'filter[channel_id]': this.selects.channel?.id,
              'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
                ? this.selects.include_special_discounts
                  ? 1
                  : 0
                : undefined,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 3) {
          await this.$store.dispatch('GET_EXPORT_REPORT_OBLIGATION_EXECUTIONS', {
            data: {
              format: 'xlsx',
              'filter[agency_id]': this.selects.agency?.id,
              'filter[grouping_type]': this.selects.grouping_type,
              'filter[advertiser_id]': this.selects.advertiser?.id,
              'filter[brand_id]': this.selects.brand?.id,
              'filter[date_from]': this.selects.date_from,
              'filter[date_to]': this.selects.date_to,
              'filter[channel_id]': this.selects.channels.map((c) => c.id),
              'filter[placement_type_id]': this.selects.type?.id,
              'filter[commercial_type_id]': this.selects.commercial?.id,
              'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
                ? this.selects.include_special_discounts
                  ? 1
                  : 0
                : undefined,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 4) {
          await this.$store.dispatch('GET_EXPORT_REPORT_SALES_PLAN_EXECUTIONS', {
            data: {
              format: 'xlsx',
              'filter[year_id]': this.selects.year?.id,
              'filter[channel_ids]': this.selects.channels.map((c) => c.id),
              'filter[commercial_type_id]': this.selects.commercial?.id,
              'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
                ? this.selects.include_special_discounts
                  ? 1
                  : 0
                : undefined,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 5) {
          await this.$store.dispatch('GET_EXPORT_REPORT_PROJECTS', {
            data: {
              format: 'xlsx',
              'filter[year_id]': this.selects.year?.id,
              'filter[agency_id]': this.selects.agencies.map((c) => c.id),
              'filter[advertiser_id]': this.selects.advertisers.map((c) => c.id),
              'filter[project_id]': this.selects.project?.id,
              'filter[channel_ids]': this.selects.channels.map((c) => c.id),
              'filter[placement_type_id]': this.selects.type?.id,
              'filter[commercial_type_id]': this.selects.commercial?.id,
              'filter[target_audience_id]': this.selects.target?.id,
              'filter[data_type]': this.selects.inventory_or_budget,
              'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
                ? this.selects.include_special_discounts
                  ? 1
                  : 0
                : undefined,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 6) {
          console.log(this.selectedReport);
          await this.$store.dispatch('GET_EXPORT_REPORT_PROJECTS_SHORT', {
            data: {
              format: 'xlsx',
              'filter[year_id]': this.selects.year?.id,
              'filter[agency_id]': this.selects.agency?.id,
              'filter[project_id]': this.selects.project?.id,
              'filter[channel_ids]': this.selects.channels.map((c) => c.id),
              'filter[placement_type_id]': this.selects.type?.id,
              'filter[commercial_type_id]': this.selects.commercial?.id,
              'filter[data_type]': this.selects.inventory_or_budget,
              'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
                ? this.selects.include_special_discounts
                  ? 1
                  : 0
                : undefined,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 7) {
          await this.$store.dispatch('GET_EXPORT_REPORT_INVENTORY', {
            data: {
              format: 'xlsx',
              'filter[date_from]': this.selects.date_from,
              'filter[date_to]': this.selects.date_to,
              'filter[channel_id]': this.selects.channels.map((c) => c.id),
              'filter[commercial_type_id]': this.selects.commercial?.id,
              'filter[target_audience_id]': this.selects.target?.id,
              'filter[time_interval]': this.selects.time_interval,
              'filter[day_interval]': this.selects.day_interval,
              'filter[time_filter]': this.selects.time_filter,
              'filter[day_filter]': this.selects.day_filter,
              // 'filter[grouping_type]': this.selects.grouping_type,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 8) {
          const selectedReleases = this.selects.program_releases_names.map((r) => r.name);
          await this.$store.dispatch('GET_EXPORT_REPORT_PROGRAMS', {
            data: {
              format: 'xlsx',
              'filter[broadcast_day_from]': this.selects.date_from,
              'filter[broadcast_day_to]': this.selects.date_to,
              'filter[channel_id]': this.selects.channel?.id,
              'filter[commercial_type_id]': this.selects.commercial?.id,
              'filter[target_audience_id]': this.selects.target?.id,
              'filter[day_interval]': this.selects.day_interval,
              'filter[start_interval]': this.selects.time_start_at,
              'filter[end_interval]': this.selects.time_end_at,
              'filter[program_release_names]': selectedReleases,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 9) {
          await this.$store.dispatch('GET_EXPORT_REPORT_INVENTORY_EXTENDED', {
            data: {
              format: 'xlsx',
              'filter[date_from]': this.selects.date_from,
              'filter[date_to]': this.selects.date_to,
              'filter[channel_ids]': this.selects.channels.map((c) => c.id),
              'filter[commercial_type_id]': this.selects.commercial?.id,
              'filter[target_audience_id]': this.selects.target?.id,
              'filter[rating_type]': this.selects.rating_type,
              'filter[day_filter]': this.selects.day_filter,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 10) {
          await this.$store.dispatch('GET_EXPORT_REPORT_CATEGORY_SUMMARY', {
            data: {
              format: 'xlsx',
              'filter[channel_ids]': this.selects.channels.map((c) => c.id),
              'filter[date_from]': this.selects.date_from,
              'filter[date_to]': this.selects.date_to,
              'filter[agency_ids]': this.selects.agencies.map((ag) => ag.id),
              'filter[advertiser_ids]': this.selects.advertisers.map((a) => a.id),
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 11) {
          await this.$store.dispatch('GET_EXPORT_REPORT_MMR_KAZAKHSTAN', {
            data: {
              format: 'xlsx',
              'filter[channel_ids]': this.selects.channels.map((c) => c.id),
              'filter[date_from]': this.selects.date_from,
              'filter[date_to]': this.selects.date_to,
              'filter[agency_ids]': this.selects.agencies.map((ag) => ag.id),
              'filter[advertiser_ids]': this.selects.advertisers.map((a) => a.id),
              'filter[commercial_type_id]': this.selects.commercial?.id,
              'filter[pre2024]': this.selects.mmr_2024_plus ? 0 : 1,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        } else if (this.selectedReport.id === 12) {
          await this.$store.dispatch('GET_EXPORT_REPORT_SALES_PLAN_EXECUTIONS_TV_NETWORK', {
            data: {
              format: 'xlsx',
              'filter[year_id]': this.selects.year?.id,
              'filter[sub_channel_ids]': this.selects.subchannels.map((c) => c.id),
              'filter[commercial_type_id]': this.selects.commercial?.id,
              'filter[target_audience_id]': this.selects.target?.id,
              'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
                ? this.selects.include_special_discounts
                  ? 1
                  : 0
                : undefined,
            },
            handler: (res) => this.prepareAndDownloadFile(res),
          });
        }
        this.processing = false;
      }
    },

    async exportReportCsv() {
      if (this.selectedReport && this.selectedReport.id === 0) {
        this.processingCsv = true;
        let data = {
          'filter[agency_id]': this.selects.agencies.map((ag) => ag.id),
          'filter[advertiser_id]': this.selects.advertisers.map((adv) => adv.id),
          'filter[brand_id]': this.selects.brands.map((b) => b.id),
          'filter[date_from]': this.selects.date_from,
          'filter[date_to]': this.selects.date_to,
          'filter[channel_ids]': this.selects.channels.map((c) => c.id),
          'filter[include_special_discounts]': this.$checkPermissions('special_discount.include', 'special_discount.exclude')
            ? this.selects.include_special_discounts
              ? 1
              : 0
            : undefined,
          'filter[include_discounts]': this.selects.with_discount_details ? 1 : 0,
          'csv[separator]': ';',
        };
        if (this.selects.spotFactTime != 'all') {
          data['filter[spots_fact_bound]'] = this.selects.spotFactTime;
        }

        await this.$store.dispatch('GET_EXPORT_STREAM_COSTS', {
          data: data,
          handler: (res) => this.prepareAndDownloadFile(res, true),
        });
      }
      this.processingCsv = false;
    },

    resetFilters() {
      const envMinHourInSec = process.env.VUE_APP_BROADCAST_START_TIME ? +process.env.VUE_APP_BROADCAST_START_TIME : 21600; //default 06:00:00
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().slice(0, 10);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().slice(0, 10);
      this.selects.date_from = firstDay;
      this.selects.date_to = lastDay;
      this.selects.agency = '';
      this.selects.agencies = [];
      this.selects.commercial = '';
      this.selects.channel = '';
      this.selects.channels = [];
      this.selects.subchannels = [];
      this.selects.brand = '';
      this.selects.brands = [];
      this.selects.advertiser = '';
      this.selects.advertisers = [];
      this.selects.type = '';
      this.selects.measurement = '';
      this.selects.target = '';
      this.selects.spotFactTime = 'only';
      this.selects.year = this.yearsList?.find((el) => el.id === +new Date().getFullYear()) || '';
      this.showFilters = true;
      this.selects.time_interval = '';
      this.selects.inventory_or_budget = 'inventory';
      this.selects.project = '';
      this.selects.day_interval = '';
      this.selects.time_filter = null;
      this.selects.day_filter = null;
      this.selects.time_start_at = envMinHourInSec;
      this.selects.time_end_at = envMinHourInSec + 24 * 60 * 60 - 1;
      this.selects.program_releases_names = [];
      this.selects.include_special_discounts = true;
      this.selects.with_discount_details = false;
      this.selects.mmr_2024_plus = true;
      this.selects.rating_type = 'plan_fact';
      this.selects.grouping_type = 0;
      this.filterAdvertisersList = [];
      this.filterBrandsList = [];
      this.$store.commit('clearBrandsList');
    },
  },
};
</script>
<style lang="sass">
.wrapper-default-page.reports-page
  padding: 60px 16px 0

  &.hiddenFilters div.table-responsive
    height: calc(100vh - 98px)

  .reports-select .multiselect__content-wrapper
    max-height: calc(100vh - 200px) !important

  table.table
    th, td
      padding: 4px
      overflow: hidden
      text-overflow: ellipsis

    div.resizable
      overflow: auto
      overflow-x: hidden
      text-overflow: ellipsis

    thead:hover div.resizable
      resize: horizontal
</style>
