<template>
  <div>
    <b-modal
      id="add-edit-channel-modal"
      size="xl"
      :title="getTitle()"
      :ok-disabled="$v.$invalid || isLoadingModal"
      :ok-title="currentItem ? $t('table.edit') : $t('table.add')"
      :cancel-title="$t('table.cancel')"
      :hide-footer="!canEdit"
      :busy="isModalBusy"
      :scrollable="true"
      @hidden="hiddenHandler"
      @ok.prevent="showModalAddChannelsConfirm"
    >
      <SpinnerLoader v-if="isLoadingModal" :loading="isLoadingModal === true ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidChannelThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <div class="row">
          <div class="col-6">
            <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.channel')" label-for="input-channel-name">
              <b-form-input
                id="input-channel-name"
                v-model.trim="name"
                required
                :class="'modal-channels-body__top-item__input'"
                type="text"
                :placeholder="$t('channelModal.nameChannel')"
                autofocus
                :disabled="!canEdit"
              />
            </b-form-group>

            <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.company')">
              <MultiSelect
                v-model="channel_company"
                :options="channelsCompany"
                :placeholder="$t('channelModal.pickCompany')"
                label="name"
                track-by="id"
                :disabled="!canEdit"
                data-automatic="ms-channel-company"
              />
              <div class="text-right">
                <b-button v-if="canEdit" id="add-channel-company-btn" v-b-modal.modal-add-company size="sm" variant="outline-danger mt-2 mb-3">
                  {{ $t('table.addCompany') }}
                </b-button>
              </div>
            </b-form-group>

            <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.year')">
              <MultiSelect
                v-model="channel_settings_years"
                :options="yearsList"
                :multiple="true"
                :placeholder="$t('channelModal.pickYear')"
                label="id"
                track-by="id"
                :disabled="!canEdit"
                :allow-empty="false"
                data-automatic="ms-year"
                @input="updateSettingsTable"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag" :style="{ padding: option.$isDisabled ? '4px 10px' : undefined }">
                    <span>{{ option.id }}</span>
                    <i v-if="!option.$isDisabled" aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
                  </span>
                </template>
              </MultiSelect>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.regionalChannel')" label-for="cb-regional-channel">
              <b-form-checkbox id="cb-regional-channel" v-model="is_regional_channel" class="mt-1" switch size="lg" :disabled="!canEdit" />
            </b-form-group>

            <template v-if="is_regional_channel">
              <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.mainChannel')">
                <MultiSelect
                  v-model="main_channel"
                  :options="channelPer"
                  :placeholder="$t('channelModal.mainChannel')"
                  label="name"
                  track-by="id"
                  :disabled="!canEdit"
                  data-automatic="ms-main-channel"
                />
              </b-form-group>

              <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.copyBroadcast')" label-for="cb-copy-broadcast">
                <b-form-checkbox id="cb-copy-broadcast" v-model="broadcasting_copy" class="mt-1" switch size="lg" :disabled="!canEdit" />
              </b-form-group>
            </template>
          </div>
        </div>
        <!-- CHANNEL SETTINGS BY YEARS -->
        <b-tabs v-model="active_tab" content-class="mt-3">
          <b-tab v-for="(ys, year) in channel_settings" :key="year" :title="year">
            <div class="row">
              <div class="col-6">
                <b-form-group label-cols="4" content-cols="8" :label="$t('holdings.holding')">
                  <MultiSelect
                    :options="holdingsList"
                    :placeholder="$t('holdings.holdings')"
                    label="name"
                    track-by="id"
                    :disabled="!canEdit"
                    data-automatic="ms-holding"
                    v-model="ys.holding"
                  />
                </b-form-group>

                <b-form-group v-if="setBroadcastTimeEnabled" label-cols="4" content-cols="8" :label="$t('channelModal.broadcastStartTime')" class="">
                  <BroadcastTimePicker
                    v-model="ys.start_interval"
                    size="sm"
                    :disabled="!canEdit || (currentItem && year < currentYear)"
                    :interval-mode="true"
                    :min-hours="0"
                    :max-hours="29"
                    required
                  />
                </b-form-group>

                <!-- CLOSING TABLE -->
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t('table.day') }}</th>
                      <th>{{ $t('table.closeDay') }}</th>
                      <th>{{ $t('table.time') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(cdt, cdtIndex) in ys.closing_datetimes" :key="year + '_' + cdtIndex">
                      <td>{{ weekDaysList[cdtIndex - 1] }}</td>
                      <td>
                        <select
                          v-model="cdt.days"
                          style="width: 80px"
                          :disabled="!canEdit || (currentItem && year < currentYear)"
                          class="custom-select custom-select-sm"
                          :required="!!cdt.start_interval"
                        >
                          <option value="">{{ $t('table.day') }}</option>
                          <option :value="1">{{ $t('table.mon') }}</option>
                          <option :value="2">{{ $t('table.tue') }}</option>
                          <option :value="3">{{ $t('table.wed') }}</option>
                          <option :value="4">{{ $t('table.thu') }}</option>
                          <option :value="5">{{ $t('table.fri') }}</option>
                          <option :value="6">{{ $t('table.sat') }}</option>
                          <option :value="7">{{ $t('table.sun') }}</option>
                        </select>
                      </td>
                      <td>
                        <BroadcastTimePicker
                          :id="`timepicker-cdt-${year}-${cdtIndex}`"
                          v-model="cdt.start_interval"
                          :min-time="ys.start_interval"
                          :max-time="ys.start_interval + 24 * 3600 - 1"
                          size="sm"
                          :disabled="!canEdit || (currentItem && year < currentYear)"
                          :interval-mode="true"
                          :required="!!cdt.days"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-6">
                <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.salesHouses')">
                  <MultiSelect
                    v-model.number="ys.saleshouse"
                    :options="salehousesList"
                    :placeholder="$t('channelModal.salesHouses')"
                    label="name"
                    track-by="id"
                    :disabled="!canEdit || (currentItem && year < currentYear)"
                    data-automatic="ms-saleshouse"
                  />
                </b-form-group>
                <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.quotaOn') + ', %'" :label-for="`quota-on-${year}`">
                  <b-form-input
                    :id="`quota-on-${year}`"
                    v-model.number="ys.sh_quota_percent_prime_time"
                    placeholder="0-100"
                    type="number"
                    step="1"
                    min="0"
                    max="100"
                    :disabled="!canEdit || (currentItem && year < currentYear)"
                  />
                </b-form-group>

                <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.quotaOff') + ', %'" :label-for="`quota-off-${year}`">
                  <b-form-input
                    :id="`quota-off-${year}`"
                    v-model.number="ys.sh_quota_percent_off_prime_time"
                    placeholder="0-100"
                    type="number"
                    step="1"
                    min="0"
                    max="100"
                    :disabled="!canEdit || (currentItem && year < currentYear)"
                  />
                </b-form-group>

                <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.measurements')">
                  <MultiSelect
                    v-model="ys.measurement_company"
                    :options="measurementsList"
                    :placeholder="$t('channelModal.measurements')"
                    label="name"
                    track-by="id"
                    :disabled="!canEdit || (currentItem && year < currentYear)"
                    data-automatic="ms-measurement"
                    required
                  />
                </b-form-group>

                <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.target')">
                  <MultiSelect
                    v-model="ys.base_target_audience"
                    :options="targetAudiencesFilteredList(ys.measurement_company)"
                    :placeholder="$t('channelModal.target')"
                    label="name"
                    track-by="id"
                    :disabled="!canEdit || (currentItem && year < currentYear)"
                    data-automatic="ms-target"
                    required
                  />
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  content-cols="8"
                  :label="`${$t('channelModal.defaultRating')} (${$t('table.plan')})`"
                  :label-for="`default-rating-plan-${year}`"
                >
                  <b-form-input
                    :id="`default-rating-plan-${year}`"
                    v-model="ys.default_plan_grp"
                    type="number"
                    min="0.01"
                    max="100"
                    placeholder="0.01-100"
                    step="0.01"
                    required
                    :disabled="!canEdit || (currentItem && year < currentYear)"
                  />
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  content-cols="8"
                  :label="`${$t('channelModal.defaultRating')} (${$t('table.fact')})`"
                  :label-for="`default-rating-fact-${year}`"
                >
                  <b-form-input
                    :id="`default-rating-fact-${year}`"
                    v-model="ys.default_fact_grp"
                    type="number"
                    min="0"
                    max="100"
                    placeholder="0-100"
                    step="0.01"
                    required
                    :disabled="!canEdit || (currentItem && year < currentYear)"
                  />
                </b-form-group>

                <b-form-group label-cols="4" content-cols="8" :label="$t('sideBar.salesHouseCommission') + ', %'" :label-for="`sh-commission-${year}`">
                  <b-form-input
                    :id="`sh-commission-${year}`"
                    v-model="ys.saleshouse_commission"
                    type="number"
                    min="-100"
                    max="100"
                    step="0.01"
                    placeholder="-100 - 100"
                    :disabled="!canEdit || (currentItem && year < currentYear)"
                  ></b-form-input>
                </b-form-group>

                <b-form-group v-if="auctionEnabled" label-cols="4" content-cols="8" :label="$t('auction.coefficientStep') + ', %'">
                  <AuctionInput v-model="ys.auction_step_coeff" />
                </b-form-group>
              </div>
            </div>

            <template v-if="FEATURES.SUBCHANNELS">
              <div class="col-12 border-top">
                <div class="w-50">
                  <b-form-group label-cols="4" content-cols="8" :label="$t('channelModal.multiChannel')" :label-for="`cb-multi_channel${year}`">
                    <b-form-checkbox :id="`cb-multi_channel${year}`" v-model="ys.is_tv_network" switch class="mt-1" size="lg" />
                  </b-form-group>

                  <template v-if="ys.is_tv_network">
                    <MultiSelect
                      :value="getSubchannelsByIds(ys.subchannels)"
                      class="w-50"
                      :options="subchannelsList"
                      :placeholder="$t('subChannels.subchannels')"
                      label="name"
                      track-by="id"
                      :disabled="!canEdit"
                      data-automatic="ms-holding"
                      :multiple="true"
                      @input="(v, id) => subchannelsSelected(v, id, ys)"
                    />
                  </template>
                </div>
              </div>

              <div v-if="ys.is_tv_network" class="mt-3">
                <SubchannelsTable :ref="`tv_network_${year}`" :settings="ys.tv_networks" :subchannels="getSubchannelsByIds(ys.subchannels)"> </SubchannelsTable>
              </div>
            </template>

            <!-- PRIME TABLE -->
            <div class="mt-3 mb-0">
              <div class="d-flex w-100 justify-content-between align-items-center">
                <div class="ml-2">{{ $t('channelModal.primeTime') }}</div>
                <b-button v-if="canEdit" size="sm" class="mb-1" :disabled="currentItem && year < currentYear" @click="addPrimeTimeRow(year)">
                  {{ $t('table.add') }}
                </b-button>
              </div>
              <table class="table table-hover vertical-align-middle">
                <thead>
                  <th style="width: 120px">{{ $t('channelModal.from') }}</th>
                  <th style="width: 120px">{{ $t('channelModal.to') }}</th>
                  <th>{{ $t('table.mon') }}</th>
                  <th>{{ $t('table.tue') }}</th>
                  <th>{{ $t('table.wed') }}</th>
                  <th>{{ $t('table.thu') }}</th>
                  <th>{{ $t('table.fri') }}</th>
                  <th>{{ $t('table.sat') }}</th>
                  <th>{{ $t('table.sun') }}</th>
                  <th style="width: 100px">{{ $t('table.actions') }}</th>
                </thead>
                <tbody>
                  <tr v-for="(prime, pttIndex) in ys.prime_time_datetimes" :key="year + '_' + pttIndex">
                    <td>
                      <BroadcastTimePicker
                        :id="`timepicker-table-input-from-${year + '_' + pttIndex}`"
                        v-model="prime.start_interval"
                        size="sm"
                        :disabled="!canEdit || (currentItem && year < currentYear)"
                        :interval-mode="true"
                        :required="!!prime.end_interval"
                        :min-time="ys.start_interval"
                        :max-time="ys.start_interval + 24 * 3600 - 1"
                      />
                    </td>
                    <td>
                      <BroadcastTimePicker
                        :id="`timepicker-table-input-to-${year + '_' + pttIndex}`"
                        v-model="prime.end_interval"
                        size="sm"
                        :disabled="!canEdit || (currentItem && year < currentYear)"
                        :interval-mode="true"
                        :required="!!prime.start_interval"
                        :min-time="ys.start_interval"
                        :max-time="ys.start_interval + 24 * 3600 - 1"
                      />
                    </td>
                    <td colspan="7">
                      <b-form-checkbox-group
                        :id="`checkbox-group-prime-time-${year + '_' + pttIndex}`"
                        v-model="prime.days"
                        :disabled="!canEdit || (currentItem && year < currentYear)"
                        class="d-flex pr-5 justify-content-between"
                      >
                        <b-form-checkbox value="1">&nbsp;</b-form-checkbox>
                        <b-form-checkbox value="2">&nbsp;</b-form-checkbox>
                        <b-form-checkbox value="3">&nbsp;</b-form-checkbox>
                        <b-form-checkbox value="4">&nbsp;</b-form-checkbox>
                        <b-form-checkbox value="5">&nbsp;</b-form-checkbox>
                        <b-form-checkbox value="6">&nbsp;</b-form-checkbox>
                        <b-form-checkbox value="7">&nbsp;</b-form-checkbox>
                      </b-form-checkbox-group>
                    </td>
                    <td>
                      <button
                        v-if="canEdit"
                        type="button"
                        class="btn-transparent"
                        :title="$t('table.delete')"
                        :disabled="currentItem && year < currentYear"
                        @click="deletePrimeTimeRow(year, pttIndex)"
                      >
                        <b-icon class="trash-hover" icon="trash"></b-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </form>
    </b-modal>
    <!-- CONFIRM -->
    <b-modal
      ref="add-channels-confirm"
      size="sm"
      :title="currentItem ? $t('table.editChannels') : $t('table.addChannels')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="addEditChannel"
    >
      <p class="my-2">
        {{ currentItem ? $t('table.confirmEditChannels') : $t('table.confirmAddChannels') }}
        <span class="text-danger">{{ name }}</span>
        ?
      </p>
    </b-modal>

    <!--MODAL ADD COMPANY-->
    <b-modal
      id="modal-add-company"
      ref="modal-add-company"
      size="sm"
      :title="$t('table.addCompany')"
      :ok-disabled="companyName.length === 0"
      :ok-title="$t('table.add')"
      :cancel-title="$t('table.cancel')"
      :busy="isModalBusy"
      @ok.prevent="addCompany"
    >
      <form @submit.stop.prevent="checkIfValidAddCompanyThenEnter">
        <b-form-group :label="$t('table.writeTitle')" label-for="input-1-company">
          <b-form-input
            id="input-channel-company-name"
            v-model.trim="companyName"
            type="text"
            :placeholder="$t('table.enterTitle')"
            autofocus
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import errorsHandler from '@/utils/errorsHandler';
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import { required, maxValue, minValue, integer, requiredIf } from 'vuelidate/lib/validators';
import SpinnerLoader from '@/components/SpinnerLoader';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';
import BroadcastTimePicker from '@/components/BroadcastTimePicker';
import { BTabs, BTab } from 'bootstrap-vue';
import AuctionInput from '../AuctionInput.vue';
import SubchannelsTable from './SubchannelsTable.vue';

export default {
  components: { MultiSelect, SubchannelsTable, SpinnerLoader, BroadcastTimePicker, BTabs, BTab, AuctionInput },
  mixins: [timeConvertHelpers],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    currentItem: {
      type: Object,
      default: undefined,
    },
    canEdit: {
      type: Boolean,
    },
  },

  data() {
    return {
      isLoadingModal: false,
      isModalBusy: false,

      companyName: '',

      active_tab: 0,
      setBroadcastTimeEnabled: process.env.VUE_APP_ENABLE_SET_BROADCAST_TIME === 'TRUE',
      auctionEnabled: process.env.VUE_APP_ENABLE_AUCTIONS === 'TRUE',
      broadcasting_copy: false,
      channel_company: null,
      channel_settings: {},
      channel_settings_years: [],
      main_channel: null,
      is_regional_channel: false,
      name: '',
      saleshouse: '',
      currentYear: +new Date().getFullYear(),
    };
  },

  computed: {
    ...mapGetters({
      yearsList: 'getYear',
      channelsCompany: 'getChannelsCompany',
      channels: 'getChannelsList',
      channelPer: 'getChannel',
      measurementsList: 'getMeasurementsList',
      salehousesList: 'getSalehousesList',
      targetList: 'getTargetList',
      modalEditChannels: 'getModalEditChannels',
      holdingsList: 'getHoldingsList',
      subchannelsList: 'getSubchannelsList',
    }),

    weekDaysList() {
      return [
        this.$i18n.t('table.mon'),
        this.$i18n.t('table.tue'),
        this.$i18n.t('table.wed'),
        this.$i18n.t('table.thu'),
        this.$i18n.t('table.fri'),
        this.$i18n.t('table.sat'),
        this.$i18n.t('table.sun'),
      ];
    },
  },

  validations: {
    name: { required },
    channel_settings_years: { required },
    channel_settings: {
      $each: {
        saleshouse: { required },
        sh_quota_percent_prime_time: { required, minValue: minValue(0), maxValue: maxValue(100) },
        sh_quota_percent_off_prime_time: { required, minValue: minValue(0), maxValue: maxValue(100) },
        measurement_company: { required },
        base_target_audience: { required }, // now required for proper Base TA GRP rating
        default_plan_grp: { required, minValue: minValue(0.01), maxValue: maxValue(100) },
        saleshouse_commission: { required, minValue: minValue(-100), maxValue: maxValue(100) },
        default_fact_grp: { required, minValue: minValue(0), maxValue: maxValue(100) },
        auction_step_coeff: {
          required: requiredIf(function () {
            return this.auctionEnabled;
          }),
          minValue: minValue(0.0001),
          maxValue: maxValue(1),
        },
      },
    },
  },

  watch: {
    async isOpen() {
      if (this.isOpen) {
        this.isLoadingModal = true;
        this.$bvModal.show('add-edit-channel-modal');

        await Promise.all([
          this.$store.dispatch('GET_CHANNELS_COMPANY', { per_page: 1000 }),
          this.$store.dispatch('GET_YEAR'), // reload years list with no modification
          this.$store.dispatch('GET_CHANNEL', { per_page: 1000 }),
          this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 }),
          this.$store.dispatch('GET_SALEHOUSES', { per_page: 1000 }),
          this.$store.dispatch('GET_TARGET', { per_page: 1000 }),
          this.$store.dispatch('GET_HOLDINGS', { per_page: 1000 }),
          this.$store.dispatch('GET_SUBCHANNELS', { per_page: 1000 }),
        ]);

        if (this.currentItem) {
          await this.$store.dispatch('GET_CHANNELS_ID', this.currentItem.id);

          if (this.modalEditChannels) {
            this.name = this.modalEditChannels.name;
            this.broadcasting_copy = this.modalEditChannels.broadcasting_copy;
            this.channel_company = this.modalEditChannels.channel_company || null;
            this.main_channel = this.modalEditChannels.main_channel || null;
            if (this.main_channel) this.is_regional_channel = true;

            let loopIndex = 0;
            const currentYear = new Date().getFullYear();

            for (const [year, el] of Object.entries(this.modalEditChannels.channel_settings)) {
              this.addYearChannelSettings(year, el);
              const yearFromList = this.yearsList.find((y) => y.id === +year); // unlink data
              if (yearFromList) {
                yearFromList.$isDisabled = true; // lock year deselect ability because it's already in DB and can't be erased
                this.channel_settings_years.push(yearFromList);
                if (currentYear == year) this.active_tab = loopIndex; // set active tab to the current year
              }
              loopIndex++;
            }
          }
        }

        for (const year of this.yearsList) {
          if (year.id < this.currentYear) year.$isDisabled = true; // lock prev years
          else if (year.id === this.currentYear) break;
        }

        this.isLoadingModal = false;
        this.$emit('openModal');
      }
    },
  },

  destroyed() {
    this.$store.commit('clearTargetsList');
    // cleanup dirty data with $isDisabled years
    this.$store.commit('clearYearsList');
  },

  methods: {
    getTitle() {
      if (this.currentItem && this.canEdit) return this.$i18n.t('table.editChannels');
      else if (this.currentItem && !this.canEdit) return this.name;
      else return this.$i18n.t('table.addNewChannels');
    },

    hiddenHandler() {
      this.clearData();
      this.$emit('updateChannels');
    },

    hideModalAddEditChannels() {
      this.hiddenHandler();
      this.$bvModal.hide('add-edit-channel-modal');
    },

    targetAudiencesFilteredList(measurement) {
      // front-end filter of TAs list when Measurement is selected
      if (measurement) return this.targetList.filter((e) => e.measurement_company_id === measurement.id);
      else return [];
    },

    updateSettingsTable(values) {
      // remove settings for deselected years
      for (const [year, el] of Object.entries(this.channel_settings)) {
        const yearSelected = this.channel_settings_years.find((y) => y.id === +year);
        if (!yearSelected) this.$delete(this.channel_settings, year); // delete property with reactivity on deleting years
      }
      // add settings for new selected years
      values.forEach((y) => {
        if (y.id && !this.channel_settings[y.id]) this.addYearChannelSettings(y.id);
      });
    },

    addYearChannelSettings(year, propsData) {
      // function to get data from backend API or from prev. year in front structure
      function getDay(i) {
        if (closing_datetimes_copy[i]?.days)
          return Array.isArray(closing_datetimes_copy[i].days) ? closing_datetimes_copy[i].days[0] || null : closing_datetimes_copy[i].days;
        else return null;
      }

      // load data from API or from previous year if exists
      const data = propsData ? propsData : this.channel_settings[`${+year - 1}`];
      const measurement_company = data?.base_target_audience?.measurement_company || data?.measurement_company || null;
      const target_audience = data?.base_target_audience || null;
      // unlink data
      const prime_time_datetimes = data?.prime_time_datetimes ? JSON.parse(JSON.stringify(data.prime_time_datetimes)) : [];
      // cleanup deprecated backend fields
      prime_time_datetimes.forEach((e) => {
        delete e.start_at;
        delete e.end_at;
        delete e.duration;
      });
      const closing_datetimes_copy = data?.closing_datetimes ? JSON.parse(JSON.stringify(data.closing_datetimes)) : [];
      const closing_datetimes = {
        1: {
          start_interval: closing_datetimes_copy[1]?.start_interval ?? null,
          days: getDay(1),
        },
        2: {
          start_interval: closing_datetimes_copy[2]?.start_interval ?? null,
          days: getDay(2),
        },
        3: {
          start_interval: closing_datetimes_copy[3]?.start_interval ?? null,
          days: getDay(3),
        },
        4: {
          start_interval: closing_datetimes_copy[4]?.start_interval ?? null,
          days: getDay(4),
        },
        5: {
          start_interval: closing_datetimes_copy[5]?.start_interval ?? null,
          days: getDay(5),
        },
        6: {
          start_interval: closing_datetimes_copy[6]?.start_interval ?? null,
          days: getDay(6),
        },
        7: {
          start_interval: closing_datetimes_copy[7]?.start_interval ?? null,
          days: getDay(7),
        },
      };
      const is_tv_network = data?.is_tv_network ? JSON.parse(JSON.stringify(data.is_tv_network)) : false;
      const tv_networks = data?.tv_networks ? JSON.parse(JSON.stringify(data.tv_networks)) : [];
      const subchannels = is_tv_network ? [...new Set(tv_networks.map((x) => x.sub_channel_id))] : [];
      // cleanup deprecated backend fields
      for (let i = 1; i <= 7; i++) {
        if (closing_datetimes[i]) delete closing_datetimes[i].time;
      }
      // use $set to retain reactivity
      this.$set(this.channel_settings, year, {
        closing_datetimes: closing_datetimes,
        prime_time_datetimes: prime_time_datetimes,
        saleshouse: data?.saleshouse,
        holding: data?.holding,
        sh_quota_percent_prime_time: data?.sh_quota_percent_prime_time ?? 0,
        sh_quota_percent_off_prime_time: data?.sh_quota_percent_off_prime_time ?? 0,
        measurement_company: measurement_company,
        base_target_audience: target_audience,
        default_plan_grp: data?.default_plan_grp ? +data.default_plan_grp : 0.01,
        default_fact_grp: data?.default_fact_grp ? +data.default_fact_grp : 0,
        saleshouse_commission: data?.saleshouse_commission ? +data.saleshouse_commission : 0,
        auction_step_coeff: data?.auction_step_coeff ? +data.auction_step_coeff : null,
        start_interval: data?.start_interval,
        is_tv_network: is_tv_network,
        tv_networks: tv_networks,
        subchannels: subchannels,
      });
    },

    addPrimeTimeRow(year) {
      this.channel_settings[year].prime_time_datetimes.push({
        days: [],
        start_interval: null,
        end_interval: null,
      });
    },

    deletePrimeTimeRow(year, index) {
      this.channel_settings[year].prime_time_datetimes.splice(index, 1);
    },

    showModalAddChannelsConfirm() {
      this.$refs['add-channels-confirm'].show();
    },

    hideModalAddChannelsConfirm() {
      this.$refs['add-channels-confirm'].hide();
    },

    async addEditChannel() {
      this.isModalBusy = true;

      const channel_settings = {};
      for (const [year, el] of Object.entries(this.channel_settings)) {
        const checkYear = this.channel_settings_years.find((y) => y.id === +year);
        if (checkYear && checkYear.$isDisabled && +year < this.currentYear) continue; // do not send settings for prev locked years
        const closing_datetimes = {};
        for (const [day, cdt] of Object.entries(el.closing_datetimes)) {
          // add only fully completed rows of closed days settings table
          if (cdt.days && cdt.start_interval) {
            closing_datetimes[day] = {
              start_interval: cdt.start_interval,
              days: [cdt.days],
            };
          }
        }

        const prime_time_datetimes = [];
        el.prime_time_datetimes.forEach((ptt) => {
          // add only fully completed rows of prime time table
          if (ptt.start_interval && ptt.end_interval && ptt.days.length)
            prime_time_datetimes.push({
              start_interval: ptt.start_interval,
              duration: ptt.end_interval - ptt.start_interval,
              days: ptt.days,
            });
        });

        channel_settings[year] = {
          base_target_audience_id: el.base_target_audience?.id,
          saleshouse_id: el.saleshouse?.id,
          sh_quota_percent_prime_time: el.sh_quota_percent_prime_time,
          sh_quota_percent_off_prime_time: el.sh_quota_percent_off_prime_time,
          closing_datetimes: closing_datetimes,
          prime_time_datetimes: prime_time_datetimes,
          default_plan_grp: el.default_plan_grp ?? undefined,
          default_fact_grp: el.default_fact_grp ?? undefined,
          saleshouse_commission: el.saleshouse_commission ?? undefined,
          auction_step_coeff: el.auction_step_coeff ?? undefined,
          start_interval: el.start_interval ?? undefined,
          holding_id: el.holding?.id,
          tv_networks: this.FEATURES.SUBCHANNELS && el.is_tv_network ? this.$refs[`tv_network_${year}`][0].getNewSettings() : undefined,
        };
      }

      const formData = {
        name: this.name,
        channel_company_id: this.channel_company?.id,
        main_channel_id: this.main_channel?.id,
        broadcasting_copy: this.broadcasting_copy,
        channel_settings: channel_settings,
      };

      if (this.currentItem) {
        formData.id = this.currentItem.id;
        await this.$store.dispatch('PUT_CHANNELS', {
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.editChannels'),
              text: this.name,
            });
            this.$emit('getChannels'); // cleanup modal data in parent
            this.hideModalAddEditChannels();
          },
          handlerError: (errors) => errorsHandler(errors, this.$notify),
        });
      } else {
        await this.$store.dispatch('POST_CHANNELS', {
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addChannels'),
              text: this.name,
            });
            this.$emit('getChannels'); // cleanup modal data in parent
            this.hideModalAddEditChannels();
          },
          handlerError: (errors) => errorsHandler(errors, this.$notify),
        });
      }
      this.hideModalAddChannelsConfirm();
      this.isModalBusy = false;
    },

    clearData() {
      this.name = '';
      this.broadcasting_copy = false;
      this.channel_company = null;
      this.channel_settings = {};
      this.channel_settings_years = [];
      this.main_channel = null;
      this.is_regional_channel = false;
      this.currentYear = +new Date().getFullYear();
      this.$store.commit('clearTargetsList');
    },

    async loadCompaniesList() {
      await this.$store.dispatch('GET_CHANNELS_COMPANY', {
        per_page: 1000,
      });
    },

    hideModalAddCompany() {
      this.$refs['modal-add-company'].hide();
    },

    async addCompany() {
      this.isModalBusy = true;
      const formData = {
        name: this.companyName,
      };

      await this.$store.dispatch('POST_CHANNELS_COMPANY', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.addCompany'),
            text: this.companyName,
          });
          this.hideModalAddCompany();
          this.companyName = '';
          this.loadCompaniesList();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },

    checkIfValidChannelThenEnter() {
      if (!this.$v.$invalid) this.showModalAddChannelsConfirm();
    },

    checkIfValidAddCompanyThenEnter() {
      if (this.companyName.length != 0) this.addCompany();
    },
    getSubchannelsByIds(ids) {
      return ids.map((id) => this.subchannelsList.find((o) => o.id == id));
    },
    subchannelsSelected(newValue, id, ys) {
      ys.subchannels = newValue.map((x) => x.id);
    },
  },
};
</script>
