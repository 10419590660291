<template>
  <div class="d-flex flex-column gap-3 mt-3">
    <b-form-group class="mb-0">
      <b-form-checkbox v-model="is_vip" class="custom-control-inline" :disabled="disabled">
        {{ $t('vip.vipSettings') }}
      </b-form-checkbox>
    </b-form-group>

    <div class="d-flex w-100 justify-content-end align-items-center pb-2">
      <b-button :disabled="disabled || is_vip == false" data-automatic="add-row" @click="addRowToProjectVip">
        {{ $t('table.addRow') }}
      </b-button>
    </div>

    <table class="table-sm small w-100" style="table-layout: fixed">
      <thead>
        <tr>
          <th style="width: 30%">%</th>
          <th style="width: 60%">
            {{ $t('table.period') }}
          </th>
          <th style="width: 10%"></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(row, index) in vip_dates" :key="index">
          <td style="width: 30%">
            <AuctionInput v-model.number="vip_dates[index].auction_coeff" :disabled="disabled || is_vip == false"></AuctionInput>
          </td>
          <td style="width: 60%">
            <div class="d-flex">
              <datepicker-wrapper
                v-model="vip_dates[index].date_from"
                :disabled="disabled || is_vip == false"
                required
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                class="pr-2"
                style="width: 50%"
                :id="'vip-date-from-' + index"
              />
              <datepicker-wrapper
                v-model="vip_dates[index].date_to"
                :disabled="disabled || is_vip == false"
                required
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :min="vip_dates[index].date_from"
                style="width: 48%"
                :id="'vip-date-to-' + index"
              />
            </div>
          </td>
          <td style="width: 70%">
            <button :disabled="disabled || is_vip == false" class="btn-transparent trash-hover" type="button" @click="deleteRowToProjectVip(index)">
              <b-icon icon="trash" :title="$t('table.delete')"></b-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <b-form-group v-if="limit != undefined" :label="$t('vip.limit_advertiser_in_auction_block')">
      <b-form-input v-model.number="limit_advertiser_in_auction_block" :disabled="disabled || !is_vip" type="number"></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import AuctionInput from '../AuctionInput.vue';
import DatepickerWrapper from '../DatepickerWrapper.vue';
export default {
  name: 'VipSettings',
  components: {
    AuctionInput,
    DatepickerWrapper,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      required: false,
      default: undefined,
    },
    isVip: {
      type: Boolean,
      required: true,
    },
    vipDates: {
      type: Array,
      required: true,
    },
  },
  computed: {
    vip_dates: {
      get() {
        return this.vipDates;
      },
      set(value) {
        this.$emit('update:vipDates', value);
      },
    },
    limit_advertiser_in_auction_block: {
      get() {
        return this.limit;
      },
      set(value) {
        this.$emit('update:limit', value);
      },
    },
    is_vip: {
      get() {
        return this.isVip;
      },
      set(value) {
        this.$emit('update:isVip', value);
      },
    },
  },
  watch: {
    is_vip(v) {
      if (!v) {
        this.vip_dates = [];
        this.limit_advertiser_in_auction_block = 0;
      }
    },
  },
  methods: {
    // vip
    addRowToProjectVip() {
      this.vip_dates.push({
        date_from: '',
        date_to: '',
        auction_coeff: 0,
      });
    },
    deleteRowToProjectVip(index) {
      this.vip_dates.splice(index, 1);
    },
  },
};
</script>
